import React from 'react';

import { clsx, truncateText } from '@digital-spiders/misc-utils';
import { FaImage, FaLink, FaVideo } from 'react-icons/fa';
import { TbFileSpreadsheet } from 'react-icons/tb';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import * as styles from './Card.module.scss';
import Image from './Image';

export interface CardType {
  image: SanityImageType;
  title: string;
  category?: string;
}

export type CardProps = CardType & {
  className?: string;
  onCardClick: () => void;
};

const Card = ({
  image,
  title,
  category,
  className,
  onCardClick,
}: CardProps): React.ReactElement => {
  const categoryIconConfig = {
    image: <FaImage />,
    video: <FaVideo />,
    spreadsheet: <TbFileSpreadsheet />,
    link: <FaLink />,
  };

  return (
    <button tabIndex={0} className={clsx(styles.container, className)} onClick={onCardClick}>
      {category && <div className={styles.categoryContainer}>{categoryIconConfig[category]}</div>}
      {image && (
        <div className={styles.imageContainer}>
          <Image
            image={image}
            alt={'Illustration of ' + title}
            className={styles.image}
            width={440}
            height={210}
            cover
          />
        </div>
      )}
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{truncateText(title, 50)}</h3>
      </div>
    </button>
  );
};

export default Card;
