import React from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import buttonArrow from '../../images/buttonArrow.svg';
import texture from '../../images/heroTexture.svg';
import { replaceNewLinesWithBr } from '../../utils/utils';
import Button from './Button';
import * as styles from './Hero.module.scss';
import Image from './Image';
import ModuleLayout from './ModuleLayout';
import SmartLink from './SmartLink';

export interface HeroProps {
  supertitle?: string;
  title: string;
  text?: string;
  image: SanityImageType;
  readersImages?: Array<SanityImageType>;
  readersNumber?: string;
  buttonAnchorLink?: string;
}

const Hero = ({
  supertitle,
  title,
  text,
  image,
  readersImages,
  readersNumber,
  buttonAnchorLink,
}: HeroProps): React.ReactElement => {
  return (
    <ModuleLayout
      className={styles.container}
      contentClassName={clsx(styles.contentContainer)}
      childrenClassName={styles.content}
    >
      <div className={styles.textContainer}>
        {supertitle && <p className={styles.superTitle}>{supertitle}</p>}
        <h1 className={styles.title}>{replaceNewLinesWithBr(title)}</h1>
        {readersImages && readersImages.length > 0 && readersNumber && (
          <div className={styles.readersContainer}>
            {readersImages.map((readerImage, i) => (
              <div className={styles.readerImageContainer} key={i}>
                <Image image={readerImage} />
              </div>
            ))}
            <span className={styles.readersNumber}>{readersNumber} readers</span>
          </div>
        )}
        {text && <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>}
        {buttonAnchorLink && (
          <SmartLink to={buttonAnchorLink} className={styles.button}>
            <Button>Explore resources</Button>
            <div className={styles.buttonArrowContainer}>
              <img src={buttonArrow} className={styles.buttonArrow} alt="" />
            </div>
          </SmartLink>
        )}
      </div>
      <div className={clsx(styles.imageContainer)}>
        <img src={texture} alt="" className={styles.texture} />
        <div className={styles.bookContainer}>
          <Image image={image} alt="Book image" className={styles.book} />
        </div>
      </div>
    </ModuleLayout>
  );
};

export default Hero;
