import React, { useEffect, useId, useRef, useState } from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import { sortBy } from '@digital-spiders/nodash';
import { withDataLayer } from '@digital-spiders/tracking-data';
import { Trans } from 'react-i18next';
import { FaInfoCircle, FaSearch } from 'react-icons/fa';
import * as styles from './SearchBox.module.scss';

const MAX_SEARCH_RESULTS = 1000;
const N_CHARS_PER_TYPO = 4;
const MS_TO_WAIT_FOR_STOP_TYPING = 1000;

const useOutsideClickDetector = (ref: React.RefObject<HTMLElement>, onOutsideClick: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};

function normalizeString(str: string) {
  let normalizedStr = str.toLowerCase();
  if (normalizedStr.normalize) {
    normalizedStr = normalizedStr.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  return normalizedStr;
}

function getTextWithBoldedMatches(
  text: string,
  searchText: string,
  renderNamePart: (namePart: string) => React.ReactNode,
): Array<React.ReactNode> {
  return (
    normalizeString(text)
      .replace(new RegExp('(' + normalizeString(searchText) + ')', 'ig'), '|||$1|||')
      .split('|||')
      // Convert normalized entry name parts to non normalized entry name parts
      .reduce((acc: Array<string>, namePart) => {
        // sum all name parts to get accLength
        const accLength = acc.reduce((a, v) => a + v.length, 0);
        return [...acc, text.slice(accLength, accLength + namePart.length)];
      }, [])
      .map((namePart, i) =>
        normalizeString(namePart) === normalizeString(searchText) ? (
          <strong key={i}>{renderNamePart(namePart)}</strong>
        ) : (
          renderNamePart(namePart)
        ),
      )
  );
}

function getLevenshteinDistanceMatrix(str1: string, str2: string) {
  const matrix = Array(str1.length + 1);
  for (let row = 0; row <= str1.length; row++) {
    matrix[row] = Array(str2.length);
  }
  matrix[0][0] = 0;
  for (let row = 1; row <= str1.length; row++) {
    matrix[row][0] = row;
  }
  for (let col = 1; col <= str2.length; col++) {
    matrix[0][col] = col;
  }
  for (let row = 1; row <= str1.length; row++) {
    for (let col = 1; col <= str2.length; col++) {
      matrix[row][col] = Math.min(
        matrix[row - 1][col] + 1,
        matrix[row][col - 1] + 1,
        matrix[row - 1][col - 1] + (str1[row - 1] === str2[col - 1] ? 0 : 1),
      );
    }
  }

  return matrix;
}

// Tests whether the searchText matches the start of targetText assuming some typos.
// The number of typos allowed depends on the size of searchText
function areTypoMatch(searchText: string, targetText: string): boolean {
  const nTyposAllowed = Math.floor(searchText.length / N_CHARS_PER_TYPO);

  for (let i = 0; i <= targetText.length - searchText.length; i++) {
    const targetTextSegment = targetText.substring(i, i + searchText.length + nTyposAllowed);
    const levenshteinDistMatrix = getLevenshteinDistanceMatrix(searchText, targetTextSegment);
    const nTypos = Math.min(...levenshteinDistMatrix[levenshteinDistMatrix.length - 1]);

    if (0 < nTypos && nTypos <= nTyposAllowed) {
      return true;
    }
  }
  return false;
}

function getMatchResults<EntryType>(
  searchText: string,
  entries: Array<Entry<EntryType>>,
  matchFieldConfigs: Record<string, string> | undefined,
  matchFunc: (searchStr: string, targetStr: string) => boolean = (searchStr, targetStr) =>
    targetStr.includes(searchStr),
): Array<
  Entry<EntryType> & {
    matchReason: MatchReason;
  }
> {
  return sortBy(
    entries
      .map(entry => ({
        ...entry,
        matchReason:
          (matchFunc(normalizeString(searchText), normalizeString(entry.name)) &&
            ('name' as const)) ||
          (entry.matchFields &&
            entry.matchFields.reduce(
              (result: null | { fieldName: string; name: string; value: string }, field) => {
                if (result) {
                  return result;
                }
                const matchedValue = field.values.find(value =>
                  matchFunc(normalizeString(searchText), normalizeString(value)),
                );
                return matchedValue
                  ? {
                      fieldName: field.name,
                      name: (matchFieldConfigs && matchFieldConfigs[field.name]) || field.name,
                      value: matchedValue,
                    }
                  : null;
              },
              null,
            )) ||
          null,
      }))
      .filter(entry => entry.matchReason),
    [
      entry => (entry.matchReason === 'name' ? 0 : 1),
      entry =>
        matchFieldConfigs && entry.matchReason !== 'name' && entry.matchReason !== null
          ? Object.keys(matchFieldConfigs).indexOf(entry.matchReason.fieldName)
          : -1,
    ],
  );
}

export interface MatchField {
  name: string;
  values: Array<string>;
}

type MatchReason =
  | 'name'
  | {
      fieldName: string;
      name: string;
      value: string;
    }
  | null;

interface Entry<EntryType> {
  name: string;
  value: EntryType;
  matchFields?: Array<MatchField>;
}

type SearchBoxProps<EntryType> = {
  className?: string;
  entriesGroups: Array<{
    title?: string;
    entries: Array<Entry<EntryType>>;
    matchFieldConfigs?: Record<string, string>;
  }>;
  placeholder?: string;
  minSearchTextChars?: number;
  withTypoCorrection?: boolean;
  noResultsMessage: string;
  onResultChosen: (value: EntryType) => void;
  renderNamePart?: (namePart: string) => React.ReactNode;
  renderResultEntry?: (entry: Entry<EntryType>, key: string | number) => React.ReactNode;
};

const SearchBox = <EntryType extends {}>({
  className,
  withTypoCorrection = false,
  entriesGroups,
  placeholder = 'Enter search term',
  minSearchTextChars = 0,
  noResultsMessage,
  onResultChosen,
  renderNamePart = namePart => namePart,
  renderResultEntry,
}: SearchBoxProps<EntryType>): React.ReactElement => {
  const [isResultsBoxOpen, setIsResultsBoxOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedMatchIndex, setSelectedMatchIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const searchInputEl = useRef<HTMLInputElement | null>(null);
  const currentResultRef = useRef<HTMLDivElement | null>(null);
  const preventNextUnfocus = useRef(false);

  useOutsideClickDetector(containerRef, () => setIsResultsBoxOpen(false));

  const groupsWithMatches = entriesGroups.map(group => {
    const matchResults = getMatchResults(searchText, group.entries, group.matchFieldConfigs);
    const typoMatchResults = withTypoCorrection
      ? getMatchResults(searchText, group.entries, group.matchFieldConfigs, areTypoMatch).filter(
          typoMatchResult =>
            !matchResults.find(matchResult => matchResult.name === typoMatchResult.name),
        )
      : [];
    return {
      ...group,
      matchResults,
      typoMatchResults,
    };
  });

  const maxSearchResultsPerGroup = Math.max(
    Math.ceil((MAX_SEARCH_RESULTS - entriesGroups.length) / entriesGroups.length),
    1,
  );

  const allMatchResults = [
    ...groupsWithMatches.flatMap(({ matchResults }) =>
      (matchResults || []).slice(0, maxSearchResultsPerGroup),
    ),
    ...groupsWithMatches.flatMap(({ typoMatchResults }) =>
      (typoMatchResults || []).slice(0, maxSearchResultsPerGroup),
    ),
  ];
  const selectedResultEntry =
    allMatchResults.length > 0 ? allMatchResults[selectedMatchIndex] : null;

  // Track search queries
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchText.length && searchText.length >= minSearchTextChars) {
        withDataLayer(dataLayer => {
          dataLayer.push({
            event: 'resourceSearch',
            text: searchText,
          });
        });
      }
    }, MS_TO_WAIT_FOR_STOP_TYPING);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    if (isResultsBoxOpen) {
      setTimeout(() => {
        if (currentResultRef.current) {
          currentResultRef.current.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        }
      }, 100);
    }
  }, [isResultsBoxOpen, selectedMatchIndex]);

  function internalRenderResultEntry(
    resultEntry: Entry<EntryType> & {
      matchReason: MatchReason;
    },
    key: string | number,
    renderPotentialMatchText: (potentialMatchText: string) => React.ReactNode,
  ) {
    return renderResultEntry ? (
      renderResultEntry(resultEntry, key)
    ) : (
      <div
        ref={resultEntry === selectedResultEntry ? currentResultRef : null}
        key={key}
        role="option"
        tabIndex={0}
        className={clsx(
          styles.result,
          resultEntry === selectedResultEntry && styles.selectedResult,
        )}
        title={resultEntry.name}
        onClick={e => {
          e.stopPropagation();
          onResultChosen(resultEntry.value);
          setIsResultsBoxOpen(false);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            onResultChosen(resultEntry.value);
            setIsResultsBoxOpen(false);
          }
        }}
        onMouseEnter={() => {
          setSelectedMatchIndex(allMatchResults.findIndex(result => result === resultEntry));
        }}
        onFocus={() => {
          setSelectedMatchIndex(allMatchResults.findIndex(result => result === resultEntry));
        }}
      >
        <span className={styles.resultName}>{renderPotentialMatchText(resultEntry.name)}</span>
        {resultEntry.matchReason !== 'name' && (
          <>
            <span className={styles.matchLocationSeparator}>|</span>
            <span className={styles.matchLocation}>
              <span className={styles.matchLocationValue}>
                {renderPotentialMatchText(resultEntry.matchReason!.value)}{' '}
              </span>
              <span className={styles.matchLocationType}>
                <Trans
                  i18nKey="search_box.search_match_location_text"
                  defaults="in {{search_match_location_type}}"
                  values={{
                    search_match_location_type: resultEntry.matchReason!.name,
                  }}
                />
              </span>
            </span>
          </>
        )}
      </div>
    );
  }

  const searchBoxInputId = 'searchBox-input-' + useId();

  return (
    <div
      ref={containerRef}
      className={styles.container + (className ? ' ' + className : '')}
      onBlur={(event: React.FocusEvent<HTMLDivElement>) => {
        const currentTarget = event.currentTarget;
        // Delay the execution to the next event loop tick to allow document.activeElement to be updated.
        setTimeout(() => {
          if (!currentTarget.contains(document.activeElement)) {
            setIsResultsBoxOpen(false);
          }
        }, 0);
      }}
    >
      {/* INPUT */}
      <div className={styles.searchBox + ' ' + styles.selected}>
        <div className={styles.button}>
          <FaSearch />
        </div>
        <label className={styles.hiddenLabel} htmlFor={searchBoxInputId}>
          {placeholder}
        </label>
        <input
          className={styles.searchInput}
          ref={searchInputEl}
          type="text"
          name=""
          id={searchBoxInputId}
          placeholder={placeholder}
          onFocus={() => setIsResultsBoxOpen(true)}
          onChange={e => {
            setSelectedMatchIndex(0);
            setSearchText(e.target.value);
          }}
          value={searchText}
          onKeyDown={event => {
            if (allMatchResults.length > 0) {
              if (event.key === 'Enter' && selectedResultEntry) {
                onResultChosen(selectedResultEntry.value);
                setIsResultsBoxOpen(false);
              } else if (event.key === 'ArrowDown') {
                setSelectedMatchIndex(
                  (selectedMatchIndex + allMatchResults.length + 1) % allMatchResults.length,
                );
              } else if (event.key === 'ArrowUp') {
                setSelectedMatchIndex(
                  (selectedMatchIndex + allMatchResults.length - 1) % allMatchResults.length,
                );
              }
            }
          }}
        />

        {/* RESULTS BOX */}
        {!!searchText && isResultsBoxOpen && (
          <div
            className={styles.resultsBox}
            onClick={() => {
              if (searchInputEl.current && searchInputEl.current !== document.activeElement) {
                preventNextUnfocus.current = true;
              }
            }}
            onScroll={() => {
              if (searchInputEl.current && searchInputEl.current !== document.activeElement) {
                preventNextUnfocus.current = true;
              }
            }}
          >
            {/* NOT ENOUGH CHARS */}
            {searchText.length < minSearchTextChars ? (
              <div className={styles.noResultsMessage}>
                <FaInfoCircle className={styles.infoIcon} /> Type at least {minSearchTextChars}{' '}
                characters to see results.
              </div>
            ) : (
              <>
                {groupsWithMatches.every(group => group.matchResults.length === 0) ? (
                  <div className={styles.noResultsMessage}>
                    <FaInfoCircle className={styles.infoIcon} /> {noResultsMessage}
                  </div>
                ) : (
                  groupsWithMatches.map(
                    (group, i) =>
                      /* MATCH RESULTS */
                      group.matchResults.length > 0 && (
                        <div
                          key={i}
                          className={
                            styles.resultsGroup +
                            (group.title ? ' ' + styles.resultsGroupTitled : '')
                          }
                        >
                          {!!group.title && <h4 className={styles.groupTitle}>{group.title}</h4>}
                          <div className={styles.groupResults}>
                            {group.matchResults
                              .slice(0, maxSearchResultsPerGroup)
                              .map((resultEntry, j) =>
                                internalRenderResultEntry(resultEntry, j, potentialMatchText =>
                                  getTextWithBoldedMatches(
                                    potentialMatchText,
                                    searchText,
                                    renderNamePart,
                                  ),
                                ),
                              )}
                            {group.matchResults.length > maxSearchResultsPerGroup && (
                              <div className={styles.moreResultsEllipsis}>...</div>
                            )}
                          </div>
                        </div>
                      ),
                  )
                )}

                {/* TYPO MATCH RESULTS */}
                {groupsWithMatches.some(group => group.typoMatchResults.length !== 0) && (
                  <div className={styles.similarResultsContainer}>
                    <div className={styles.similarResultsTitle}>Similar results</div>
                    <div className={styles.similarResults}>
                      {groupsWithMatches
                        .filter(group => group.typoMatchResults.length)
                        .map(group =>
                          group.typoMatchResults
                            // Didn't bother with a different logic for limiting results
                            // as it's not a problem for now
                            .slice(0, maxSearchResultsPerGroup)
                            .map((resultEntry, j) =>
                              internalRenderResultEntry(resultEntry, j, potentialMatchText =>
                                renderNamePart(potentialMatchText),
                              ),
                            ),
                        )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
