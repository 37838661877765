import '../styles/main.scss';

import { graphql } from 'gatsby';
import React, { useState } from 'react';

import { slugify, urlJoin } from '@digital-spiders/misc-utils';
import { sortBy } from '@digital-spiders/nodash';
import { useIntersectionObserver } from '@digital-spiders/react-hooks';
import groq from 'groq';
import PageSEO from '../components/PageSEO';
import Hero from '../components/ui/Hero';
import BooksModule from '../components/ui/modules/BooksModule';
import CTAModule from '../components/ui/modules/CTAModule';
import ResourcesModule, { Resource } from '../components/ui/modules/ResourcesModule';
import TextAndMediaModule from '../components/ui/modules/TextAndMediaModule';
import SanityLayout from '../components/ui/SanityLayout';
import { HOOK_POINT_WEBSITE_URL } from '../constants';
import { Book } from '../graphql-fragments/Book';
import { SingleEntry } from '../graphql-fragments/fragments';
import { SanityImageType } from '../graphql-fragments/SanityImage';
import { LocalizedSEOWebsite } from '../graphql-fragments/seo';
import { withPagePreview } from '../preview/previewUtils';
import { PageDocument, RawPortableText } from '../types/types';

const HERO_VISIBILITY_RATIO_FOR_CTA_BUTTON = 0.7;

export const query = graphql`
  fragment Hero on SanityLpBookHero {
    title
    text
    image {
      ...SanityImage
    }
  }

  query GetPageById($id: String!) {
    bookLandingPage: sanityLpBook(_id: { eq: $id }) {
      _id
      title
      slug {
        current
      }
      initialVideoUrl
      hero {
        ...Hero
      }
      bookReference {
        _id
      }
      seo {
        ...SEOWebsite
      }
    }
    sanityLpBookCommonFields {
      ...CommonFields
    }
    allSanityBookResource {
      nodes {
        _id
        book {
          _id
        }
        resourceType
        title
        image {
          ...SanityImage
        }
        videoUrl
        spreadsheetUrl
        section {
          _id
          title
          slug {
            current
          }
        }
        spreadsheetRowNumber
      }
    }
    allSanityBookSection(sort: { orderRank: ASC }) {
      nodes {
        _id
        title
        slug {
          current
        }
        book {
          _id
        }
      }
    }
    allBooks: allSanityBook(sort: { orderRank: ASC }) {
      nodes {
        ...Book
      }
    }
  }
`;

const groqQuery = groq`{
  "bookLandingPage": *[_id == $id][0] {
     ...
  }
}`;

export interface MenuFields {
  title: string;
  menuTitle?: string;
  moduleId?: string;
}

interface QueryData {
  bookLandingPage: {
    _id: string;
    title: string;
    slug: {
      current: string;
    };
    initialVideoUrl?: string;
    hero: {
      title: string;
      text: string;
      image: SanityImageType;
    };
    bookReference: Book;
    seo: LocalizedSEOWebsite;
  };
  sanityLpBookCommonFields: {
    hero: {
      readersImages: Array<SanityImageType>;
      readersNumber: string;
    };
    resourcesSection: MenuFields & {
      text: string;
    };
    authorSection: MenuFields & {
      _rawText: RawPortableText;
      mediaType: 'image' | 'video';
      image?: SanityImageType;
      video?: string;
      mediaSubtitle?: string;
      moduleAligment: 'textLeftImageRight' | 'imageLeftTextRight';
    };
    booksSection: MenuFields & {
      text: string;
    };
    ctaSection: MenuFields & {
      text: string;
      floatingButtonText?: string;
      buttonText: string;
      buttonPageReference: PageDocument;
      scheduleOnceParam?: string;
    };
  };
  allSanityBookResource: {
    nodes: Array<Resource>;
  };
  allSanityBookSection: {
    nodes: Array<{
      _id: string;
      title: string;
      slug: {
        current: string;
      };
      book: {
        _id: string;
      };
    }>;
  };
  allBooks: {
    nodes: Array<Book>;
  };
}

const Page = withPagePreview<QueryData>(
  {
    groqQuery,
  },
  ({ data }): React.ReactElement => {
    const { title, slug, initialVideoUrl, hero, seo } = data.bookLandingPage;
    const { resourcesSection, authorSection, booksSection, ctaSection } =
      data.sanityLpBookCommonFields;

    // Active section filter
    const [activeSectionId, setActiveSectionId] = useState<string | null>(null);

    const resourcesFilteredByBook = data.allSanityBookResource.nodes.filter(
      resource => resource.book?._id === data.bookLandingPage.bookReference._id,
    );
    const sectionsFilteredByBook = data.allSanityBookSection.nodes.filter(
      section => section.book?._id === data.bookLandingPage.bookReference._id,
    );

    const allResourcesSorted = sortBy(
      resourcesFilteredByBook,
      resource => resource.spreadsheetRowNumber,
      'asc',
    );

    const usedSections = sectionsFilteredByBook
      .filter(section =>
        allResourcesSorted.some(
          resource => resource.section && resource.section._id === section._id,
        ),
      )
      .map(section => section);

    const resourcesFilteredBySection = allResourcesSorted.filter(
      resource => resource.section?._id === activeSectionId,
    );

    const booksToShow = data.allBooks.nodes
      .filter(book => book._id !== data.bookLandingPage.bookReference._id)
      .map(book => ({
        ...book,
        description: book.description,
        mobileImage: book.mobileImage,
        image: book.sideMockupCover,
        link: book.page,
      }));

    const resourcesAnchorLink = urlJoin(
      slug.current,
      '/#',
      resourcesSection.moduleId || slugify(resourcesSection.title),
    );

    const ctaSectionAnchor = urlJoin(
      slug.current,
      '/#',
      ctaSection.moduleId || slugify(ctaSection.title),
    );

    const menuEntries: Array<SingleEntry> = [
      ...(allResourcesSorted.length > 0
        ? [
            {
              title: resourcesSection.menuTitle || resourcesSection.title,
              anchor: resourcesAnchorLink,
            },
          ]
        : []),
      {
        title: authorSection.menuTitle || authorSection.title,
        anchor: urlJoin(slug.current, '/#', authorSection.moduleId || slugify(authorSection.title)),
      },
      ...(booksToShow.length > 0
        ? [
            {
              title: booksSection.menuTitle || booksSection.title,
              anchor: urlJoin(
                slug.current,
                '/#',
                booksSection.moduleId || slugify(booksSection.title),
              ),
            },
          ]
        : []),
      {
        title: ctaSection.menuTitle || ctaSection.title,
        anchor: ctaSectionAnchor,
      },
    ];

    const [heroRef, heroIntersection] = useIntersectionObserver({
      threshold: HERO_VISIBILITY_RATIO_FOR_CTA_BUTTON,
    });
    const [ctaRef, ctaIntersection] = useIntersectionObserver();

    return (
      <SanityLayout
        menuEntries={menuEntries}
        initialVideoUrl={initialVideoUrl}
        withUserSession={allResourcesSorted.length > 0}
        headerCtaButton={
          allResourcesSorted.length < 1
            ? {
                text: ctaSection.buttonText,
                anchorLink: ctaSectionAnchor,
              }
            : undefined
        }
        floatingButton={{
          text: ctaSection.floatingButtonText || ctaSection.buttonText,
          anchorLink: ctaSectionAnchor,
          isVisible:
            ((heroIntersection && ctaIntersection) || false) &&
            heroIntersection.intersectionRatio < HERO_VISIBILITY_RATIO_FOR_CTA_BUTTON &&
            !ctaIntersection.isIntersecting,
        }}
      >
        <PageSEO pageSEO={seo} />
        <div ref={heroRef}>
          <Hero
            supertitle={title + ':'}
            title={hero.title}
            text={hero.text}
            image={hero.image}
            buttonAnchorLink={allResourcesSorted.length > 0 ? resourcesAnchorLink : undefined}
            readersImages={data.sanityLpBookCommonFields.hero.readersImages}
            readersNumber={data.sanityLpBookCommonFields.hero.readersNumber}
          />
        </div>
        {allResourcesSorted.length > 0 && (
          <ResourcesModule
            id={resourcesSection.moduleId}
            title={resourcesSection.title}
            text={resourcesSection.text}
            sections={usedSections}
            resources={allResourcesSorted}
            resourcesFilteredBySection={resourcesFilteredBySection}
            activeSectionId={activeSectionId}
            setActiveSectionId={setActiveSectionId}
          />
        )}
        <TextAndMediaModule id={authorSection.moduleId} {...authorSection} />
        {booksToShow.length > 0 && (
          <BooksModule moduleId={booksSection.moduleId} books={booksToShow} {...booksSection} />
        )}
        <div ref={ctaRef}>
          <CTAModule
            id={ctaSection.moduleId}
            title={ctaSection.title}
            text={ctaSection.text}
            buttonText={ctaSection.buttonText}
            buttonUrl={urlJoin(
              HOOK_POINT_WEBSITE_URL,
              ctaSection.buttonPageReference.slug.current,
              ctaSection.scheduleOnceParam ? `?socalendar=${ctaSection.scheduleOnceParam}` : '',
            )}
          />
        </div>
      </SanityLayout>
    );
  },
);

export default Page;
