import React, { useEffect, useState } from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import { mapBy } from '@digital-spiders/nodash';
import { FaChevronDown, FaTimes } from 'react-icons/fa';
import * as styles from './SectionFilter.module.scss';
import { useUpdateUrlFromFilters } from '@digital-spiders/react-hooks';

export interface SectionFilterProps {
  sections: Array<{
    _id: string;
    title: string;
    slug: {
      current: string;
    };
  }>;
  activeSectionId: string | null;
  setActiveSectionId: (sectionId: string | null) => void;
  className?: string;
}

const SectionFilter = ({
  sections,
  activeSectionId,
  setActiveSectionId,
  className,
}: SectionFilterProps): React.ReactElement => {
  const [isFilterContainerOpened, setFilterContainerOpened] = useState(false);

  const sectionsById = mapBy(sections, '_id');

  // Get initial filters state from url
  useEffect(() => {
    let initialSectionId: string | null = null;

    const sectionsBySlug = mapBy(sections, section => section.slug.current);

    const currentUrl = new URLSearchParams(window.location.search);
    const urlSectionSlugEncoded = currentUrl.get('section');

    if (urlSectionSlugEncoded) {
      const initialSection = sectionsBySlug[decodeURIComponent(urlSectionSlugEncoded)];
      initialSectionId = initialSection ? initialSection._id : null;
    }

    setActiveSectionId(initialSectionId);
  }, []);

  // Update url on filters state change
  useUpdateUrlFromFilters(() => {
    const section = activeSectionId ? sectionsById[activeSectionId] : null;
    const sectionSlugEncoded = section ? encodeURIComponent(section.slug.current) : null;

    return [['section', sectionSlugEncoded] as const];
  }, [activeSectionId]);

  const activeSectionTitle =
    activeSectionId &&
    sections.filter(section => section._id === activeSectionId).map(section => section.title);

  return (
    <div className={clsx(styles.container, className)}>
      <div
        className={styles.filterContainer}
        onBlur={(event: React.FocusEvent<HTMLDivElement>) => {
          const currentTarget = event.currentTarget;
          // Delay the execution to the next event loop tick to allow document.activeElement to be updated.
          setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
              setFilterContainerOpened(false);
            }
          }, 0);
        }}
      >
        <div className={clsx(styles.filterWrapper, isFilterContainerOpened && styles.open)}>
          <button
            className={clsx(styles.filterBox, activeSectionId !== null && styles.withCloseIcon)}
            onClick={() => {
              setFilterContainerOpened(!isFilterContainerOpened);
            }}
          >
            <span className={clsx(styles.sectionTitle, !activeSectionTitle && styles.placeholder)}>
              {activeSectionTitle || 'Section'}
            </span>
            <FaChevronDown className={clsx(styles.filterIcon)} />
          </button>
          {activeSectionId !== null && (
            <button
              onClick={() => {
                setActiveSectionId(null);
                setFilterContainerOpened(false);
              }}
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              className={clsx(styles.closeButton, activeSectionId !== null && styles.active)}
            >
              <FaTimes className={clsx(styles.closeIcon)} />
            </button>
          )}
        </div>
        {isFilterContainerOpened && (
          <div className={styles.filterOpenedContainer}>
            {sections.map((section, i) => (
              <button
                key={i}
                tabIndex={activeSectionId === section._id ? -1 : 0}
                className={clsx(
                  styles.sectionContainer,
                  activeSectionId === section._id && styles.active,
                )}
                onClick={() => {
                  setFilterContainerOpened(false);
                  setActiveSectionId(section._id);
                }}
              >
                <span className={styles.sectionTitle}>{section.title}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionFilter;
