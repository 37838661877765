import { slugify, urlJoin } from '@digital-spiders/misc-utils';
import React from 'react';
import { Book } from '../../../graphql-fragments/Book';
import ButtonLink from '../ButtonLink';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './BooksModule.module.scss';

export type BooksModuleProps = {
  title: string;
  text: string;
  books: Array<Book>;
  className?: string;
  moduleId?: string;
};

function BooksModule(props: BooksModuleProps): React.ReactElement {
  const { title, text, books, className, moduleId } = props;

  return (
    <ModuleLayout
      id={moduleId || slugify(title)}
      className={className}
      title={title}
      text={text}
      childrenClassName={styles.booksContainer}
    >
      {books.map((book, i) => (
        <React.Fragment key={i}>
          <div className={styles.bookContainer}>
            <div className={styles.imageContainer}>
              <Image
                image={book.mobileImage}
                alt={book.title + ' Book'}
                className={styles.bookMobileImage}
              />
              <Image
                image={book.sideMockupCover}
                alt={book.title + ' Book'}
                className={styles.bookDesktopImage}
              />
            </div>
            <div className={styles.textContainer}>
              <h3 className={styles.bookTitle}>{book.title}</h3>
              <p className={styles.bookDescription}>{book.description}</p>
              {book.page?.slug?.current && (
                <div className={styles.buttonsContainer}>
                  {book.page && (
                    <ButtonLink
                      outlined
                      to={{ url: urlJoin('https://hookpoint.com', book.page.slug.current) }}
                      linkClassName={styles.buttonContainer}
                      className={styles.button}
                    >
                      Read more
                    </ButtonLink>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.divider}></div>
        </React.Fragment>
      ))}
    </ModuleLayout>
  );
}

export default BooksModule;
