export interface PageCreationData {
  slug: {
    current: string;
  };
  _id: string;
}

export interface PagesCreationQueryData {
  allSanityLpBook: {
    nodes: Array<PageCreationData>;
  };
}

export const pagesCreationGraphqlQuery = `
  {
    allSanityLpBook {
      nodes {
        slug {
          current
        }
        _id
      }
    }
  }
`;

const pageCreationDataGroqFragment = `
  slug {
    current
  },
  _id,
`;

export const pagesCreationGroqQuery = `
  {
    "allSanityLpBook": {
      "nodes": *[_type == "lp-book"] {
        ${pageCreationDataGroqFragment}
      }
    }
  }
  `;

interface PageCreationConfig {
  path: string;
  component: string;
  context?: Record<string, unknown>;
}

export function getPagesToCreate(
  pagesCreationQueryData: PagesCreationQueryData,
): Array<PageCreationConfig> {
  const { allSanityLpBook } = pagesCreationQueryData;

  function getPageBaseContext(page: PageCreationData) {
    return {
      id: page._id,
    };
  }

  return [
    ...allSanityLpBook.nodes.map(page => ({
      path: page.slug.current,
      component: './src/templates/Page.tsx',
      context: getPageBaseContext(page),
    })),
  ];
}
