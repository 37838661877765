// extracted by mini-css-extract-plugin
export var bookContainer = "ResourcesModule-module--bookContainer--60149";
export var bookDescription = "ResourcesModule-module--bookDescription--b47e7";
export var bookDesktopImage = "ResourcesModule-module--bookDesktopImage--2ce36";
export var bookMobileImage = "ResourcesModule-module--bookMobileImage--c8477";
export var bookTitle = "ResourcesModule-module--bookTitle--425a4";
export var booksContainer = "ResourcesModule-module--booksContainer--40b0d";
export var button = "ResourcesModule-module--button--dc948";
export var buttonContainer = "ResourcesModule-module--buttonContainer--1974d";
export var buttonsContainer = "ResourcesModule-module--buttonsContainer--c7ffd";
export var divider = "ResourcesModule-module--divider--03213";
export var filterContainer = "ResourcesModule-module--filterContainer--0deb0";
export var filterTitle = "ResourcesModule-module--filterTitle--d3702";
export var filtersContainer = "ResourcesModule-module--filtersContainer--290f8";
export var icon = "ResourcesModule-module--icon--3d4c3";
export var imageContainer = "ResourcesModule-module--imageContainer--c9768";
export var modalContainer = "ResourcesModule-module--modalContainer--75cf1";
export var modalTitle = "ResourcesModule-module--modalTitle--52821";
export var searchContainer = "ResourcesModule-module--searchContainer--b5da3";
export var sectionTitle = "ResourcesModule-module--sectionTitle--1ce8d";
export var textContainer = "ResourcesModule-module--textContainer--38edd";