import React from 'react';

import { SITE_DOMAIN } from '../constants';

type StrPartPreprocesser = (str: string) => React.ReactNode;

function replaceSplitterWithEl(
  str: string,
  splitter: string | RegExp,
  el: React.ReactNode,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  const splitStr = str.split(splitter).map(strPart => preprocessStrPart(strPart));
  const splitStrWithEls: Array<React.ReactNode> = [];
  for (let i = 0; i < splitStr.length; i++) {
    splitStrWithEls.push(<React.Fragment key={i * 2}>{splitStr[i]}</React.Fragment>);
    splitStrWithEls.push(<React.Fragment key={i * 2 + 1}>{el}</React.Fragment>);
  }
  splitStrWithEls.pop();
  return splitStrWithEls;
}

export function replaceNewLinesWithBr(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  return replaceSplitterWithEl(str, '\n', <br></br>, preprocessStrPart);
}

export function checkIsInternalUrl(url: string): boolean {
  if (url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(?:\/|$)`))) {
    return true;
  } else if (url.match(/^\w+:\/\//)) {
    return false;
  } else if (url.match(/^(tel|fax|mailto):/)) {
    return false;
  } else {
    return true;
  }
}

export function getInternalUrlPath(url: string): string {
  if (!checkIsInternalUrl(url)) {
    throw new Error('Called getInternalUrlPath with a non internal url: ' + url);
  }
  if (!url.startsWith('http')) {
    return url;
  }
  const match = url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(.*)`));
  if (match === null) {
    throw new Error('Got null match from supposedly internal url: ' + url);
  }
  const path = match[1];
  if (path === '') {
    return '/';
  }
  if (!path.startsWith('/')) {
    throw new Error("Url path should start with slash but doesn't: " + url);
  }

  return path;
}
