import React, { useContext } from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import { LocationContext } from '../../contexts/LocationContext';
import { SingleEntry } from '../../graphql-fragments/fragments';
import nestedArrow from '../../images/nestedArrow.svg';
import * as styles from './MenuEntry.module.scss';
import TextLink from './TextLink';

export interface MenuEntryProps {
  entry: SingleEntry;
  withLeftArrow?: boolean;
  isSubEntry?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
}

const MenuEntry = ({
  entry,
  withLeftArrow,
  isSubEntry,
  onClick,
  className,
}: MenuEntryProps): React.ReactElement => {
  const location = useContext(LocationContext);

  return (
    <TextLink
      onClick={onClick}
      to={entry.anchor}
      className={clsx(styles.link, location && isSubEntry && styles.subEntry, className)}
    >
      {withLeftArrow && <img src={nestedArrow} alt="" className={styles.arrowIcon} />}
      <div className={styles.titleContainer}>
        <span className={styles.title}>{entry.title}</span>
      </div>
    </TextLink>
  );
};

export default MenuEntry;
