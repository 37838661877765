// extracted by mini-css-extract-plugin
export var active = "SectionFilter-module--active--ac290";
export var closeButton = "SectionFilter-module--closeButton--e3b84";
export var filterBox = "SectionFilter-module--filterBox--ed801";
export var filterContainer = "SectionFilter-module--filterContainer--01426";
export var filterIcon = "SectionFilter-module--filterIcon--9e001";
export var filterOpenedContainer = "SectionFilter-module--filterOpenedContainer--b0330";
export var filterWrapper = "SectionFilter-module--filterWrapper--57c48";
export var open = "SectionFilter-module--open--a6cf3";
export var placeholder = "SectionFilter-module--placeholder--0bcdd";
export var sectionContainer = "SectionFilter-module--sectionContainer--41387";
export var sectionTitle = "SectionFilter-module--sectionTitle--3a3eb";
export var withCloseIcon = "SectionFilter-module--withCloseIcon--d13be";