// extracted by mini-css-extract-plugin
export var book = "Hero-module--book--4fc17";
export var bookContainer = "Hero-module--bookContainer--6a68a";
export var bookStamp = "Hero-module--bookStamp--fc80f";
export var button = "Hero-module--button--cf901";
export var buttonArrow = "Hero-module--buttonArrow--7c885";
export var buttonArrowContainer = "Hero-module--buttonArrowContainer--d85ee";
export var content = "Hero-module--content--68236";
export var contentContainer = "Hero-module--contentContainer--51c42";
export var imageContainer = "Hero-module--imageContainer--46dc8";
export var readerImageContainer = "Hero-module--readerImageContainer--308ac";
export var readersContainer = "Hero-module--readersContainer--6ddf3";
export var readersNumber = "Hero-module--readersNumber--b480b";
export var rotate = "Hero-module--rotate--f0759";
export var superTitle = "Hero-module--superTitle--1ff3b";
export var text = "Hero-module--text--0752e";
export var textContainer = "Hero-module--textContainer--d59bd";
export var texture = "Hero-module--texture--dd39d";
export var title = "Hero-module--title--044fe";