/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import './src/translation/i18n';

import React from 'react';

import { TrackingDataIframe } from '@digital-spiders/react-tracking-data';
import { storeHookPointTrackingData } from '@hook-point/tracking-data';
import * as Sentry from '@sentry/react';
import { GatsbyBrowser } from 'gatsby';
import { AuthProvider } from './src/auth/AuthContext';
import { ROOT_DOMAIN, SITE_DOMAIN } from './src/constants';
import { LocationContext } from './src/contexts/LocationContext';
import PreviewConnectorProvider from './src/preview/PreviewConnectorProvider';
import { PreviewStateProvider } from './src/preview/PreviewStateContext';
import { GlobalStateProvider } from './src/state/globalStateContext';

// You can delete this file if you're not using it
// export const onClientEntry = () => {
//     // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//     if (!(`IntersectionObserver` in window)) {
//       import(`intersection-observer`);
//     }
//   };
// import { I18nextProvider } from 'react-i18next';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return (
    /* <I18nextProvider i18n={i18n}> */
    <>
      <TrackingDataIframe
        rootDomain={ROOT_DOMAIN}
        onRender={() => {
          storeHookPointTrackingData({
            currentDomain: SITE_DOMAIN,
            rootDomain: ROOT_DOMAIN,
            serverReadyTimeout: 10000,
          }).catch(error => {
            console.error('Error storing tracking data', error);
            Sentry.captureException(error);
          });
        }}
      />
      <GlobalStateProvider>
        <AuthProvider>
          <LocationContext.Provider value={props.location}>
            <PreviewStateProvider>
              <PreviewConnectorProvider>{element}</PreviewConnectorProvider>
            </PreviewStateProvider>
          </LocationContext.Provider>
        </AuthProvider>
      </GlobalStateProvider>
    </>
    /* </I18nextProvider> */
  );
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};
