import React from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import SmartLink, { SmartLinkProps } from './SmartLink';
import * as styles from './TextLink.module.scss';

export type TextLinkProps = Omit<SmartLinkProps, 'to' | 'openInNewTab'> &
  (
    | {
        styleOnly: true;
        withExternalIcon?: boolean;
        to?: never;
        openInNewTab?: never;
        role?: string;
      }
    | {
        styleOnly?: never;
        withExternalIcon?: never;
        to: string;
        openInNewTab?: boolean;
        role?: never;
      }
  );

export default React.forwardRef<any, TextLinkProps>(function TextLink(
  {
    openInNewTab,
    styleOnly,
    className,
    children,
    to,
    withExternalIcon = false,
    role,
    ...restProps
  },
  ref,
): React.ReactElement {
  if (styleOnly) {
    return (
      <span className={clsx(styles.link, className)} {...restProps} role={role} tabIndex={0}>
        {children}
      </span>
    );
  } else {
    return (
      <SmartLink
        openInNewTab={openInNewTab}
        ref={ref}
        to={to}
        className={clsx(styles.link, className)}
        {...restProps}
      >
        {children}
      </SmartLink>
    );
  }
});
