import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import usePortal from 'react-useportal';

import { clsx } from '@digital-spiders/misc-utils';
import { useGlobalState } from '../../state/globalStateContext';
import Modal from './Modal';
import * as styles from './ModalOverlay.module.scss';

export interface ModalOverlayProps {
  open: boolean;
  positionY?: number;
  className?: string;
  onClose: React.MouseEventHandler;
  children: React.ReactNode;
  title?: string;
  introText?: string;
  renderWhenHidden?: boolean;
  modalClassName?: string;
  modalContainerClassName?: string;
}

const ModalOverlay = ({
  open,
  positionY,
  className,
  children,
  title,
  introText,
  onClose,
  renderWhenHidden,
  modalClassName,
  modalContainerClassName,
}: ModalOverlayProps): React.ReactElement => {
  const { isEmbedded } = useGlobalState();
  const { Portal } = usePortal();
  const rootRef = useRef<HTMLDivElement>(null);
  const [modalWrapperRef, setModalWrapperRef] = useState<HTMLDivElement | null>(null);
  const originalDocumentOverflow = useRef<null | string>(null);
  const [adjustedPositionY, setAdjustedPositionY] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (isEmbedded && typeof ResizeObserver !== 'undefined' && modalWrapperRef) {
      const handleResize = () => {
        if (modalWrapperRef && positionY) {
          const modalHeight = modalWrapperRef.getBoundingClientRect().height;
          const windowHeight = window.document.documentElement.getBoundingClientRect().height;
          const maxPaddingUsed = 20;
          setAdjustedPositionY(
            Math.min(
              Math.max(positionY, modalHeight / 2 + maxPaddingUsed),
              windowHeight - modalHeight / 2 - maxPaddingUsed,
            ),
          );
        }
      };
      const resizeObserver = new ResizeObserver(() => handleResize());

      resizeObserver.observe(modalWrapperRef);
      return () => resizeObserver.disconnect();
    }
    return;
  }, [isEmbedded, modalWrapperRef, positionY]);

  // The `open` prop only mean the component above wants to open the modal
  // but whether the modal is actually rendered and visible is controlled
  // by these two states. These are controlled in the useEffect below
  // in a way that allows for opening and closing animations.
  const [shouldRender, setShouldRender] = useState(!!renderWhenHidden);
  const [visible, setVisible] = useState(false);

  const previousOpenRef = useRef<boolean | null>(null);
  const skipNextOverlayClick = useRef(false);

  useEffect(() => {
    if (open) {
      originalDocumentOverflow.current = document.documentElement.style.overflow || '';
      document.documentElement.style.overflow = 'hidden';

      if (renderWhenHidden) {
        setVisible(true);
      } else {
        setShouldRender(true);
        // wait a bit before setting visible to true so that
        // opening animations run as the modal overlay gets
        // first rendered hidden and only then turned visible
        setTimeout(() => {
          setVisible(true);
        }, 50);
      }
    } else {
      if (originalDocumentOverflow.current !== null) {
        document.documentElement.style.overflow = originalDocumentOverflow.current || '';
        originalDocumentOverflow.current = null;
      }

      if (renderWhenHidden) {
        setVisible(false);

        if (previousOpenRef.current !== null && open !== previousOpenRef.current) {
          // Scroll to top of content on closing so it starts
          // from the top when opened again
          setTimeout(() => {
            if (rootRef.current) {
              rootRef.current.scrollTo(0, 0);
            }
          }, 500);
        }
      } else {
        setVisible(false);

        if (previousOpenRef.current !== null && open !== previousOpenRef.current) {
          // Wait a bit before we stop rendering to allow for
          // closing animations to run
          setTimeout(() => {
            setShouldRender(false);
          }, 300);
        }
      }
    }
    previousOpenRef.current = open;
  }, [open]);
  return shouldRender ? (
    <Portal>
      <div
        ref={rootRef}
        className={clsx(
          styles.overlay,
          visible && styles.visible,
          isEmbedded && styles.embedded,
          className,
        )}
        onClick={e => {
          e.stopPropagation();
          if (skipNextOverlayClick.current) {
            skipNextOverlayClick.current = false;
          } else {
            onClose(e);
          }
        }}
      >
        <div
          className={styles.modalContainer}
          style={
            isEmbedded
              ? {
                  top: positionY ? adjustedPositionY + 'px' : '50%',
                  transform: 'translateY(-50%)',
                }
              : undefined
          }
          onClick={e => e.stopPropagation()}
        >
          <div ref={setModalWrapperRef} className={styles.modalWrapper}>
            <Modal
              onClose={onClose}
              onMouseDown={() => (skipNextOverlayClick.current = true)}
              onMouseUp={() => (skipNextOverlayClick.current = false)}
              title={title}
              introText={introText}
              className={modalContainerClassName}
              childrenClassName={modalClassName}
            >
              {children}
            </Modal>
          </div>
          {!isEmbedded && <div className={styles.modalDecenterSpace} onClick={onClose}></div>}
        </div>
      </div>
    </Portal>
  ) : (
    <></>
  );
};

export default ModalOverlay;
