import { clsx } from '@digital-spiders/misc-utils';
import { withDataLayer } from '@digital-spiders/tracking-data';
import React from 'react';

import ReactPlayer from 'react-player';
import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';
import { getVideoTypeByUrl } from '../../utils/projectUtils';
import * as styles from './EmbedModal.module.scss';

export type EmbedModalProps = {
  url: string;
  hideVideoViewsInfo?: boolean;
  isAVerticalVideo?: boolean;
  className?: string;
};

const EmbedModal = ({
  url,
  hideVideoViewsInfo,
  isAVerticalVideo,
  className,
}: EmbedModalProps): React.ReactElement => {
  const type = getVideoTypeByUrl(url);
  return (
    <div className={clsx(styles.modalContent, className)}>
      {type === 'tiktok' && (
        <div className={clsx(styles.embed, styles.tiktok)}>
          <TikTokEmbed url={url} />
          {hideVideoViewsInfo && <div className={styles.hiddenInfo}></div>}
        </div>
      )}
      {type === 'instagram' && (
        <div className={clsx(styles.embed, styles.instagram)}>
          <InstagramEmbed url={url} />
          {hideVideoViewsInfo && <div className={styles.hiddenInfo}></div>}
        </div>
      )}
      {(type === 'youtube' || type === 'wistia' || type === 'vimeo') && (
        <ReactPlayer
          url={url}
          controls
          width="100vw"
          height={isAVerticalVideo ? '100%' : 'auto'}
          style={{
            aspectRatio: isAVerticalVideo ? '9/16' : '16/9',
            background: 'black',
            position: 'relative',
            maxWidth: isAVerticalVideo ? '100%' : '100%',
            overflow: 'hidden',
          }}
          onStart={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'video-start', videoUrl: url });
            });

            // if (props.onStart) {
            //   props.onStart();
            // }
          }}
        />
      )}
    </div>
  );
};

export default EmbedModal;
