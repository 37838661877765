import { clsx } from '@digital-spiders/misc-utils';
import React from 'react';

import * as styles from './Menu.module.scss';
import MenuEntry from './MenuEntry';

interface MenuProps {
  entries: Array<{
    title: string;
    anchor: string;
  }>;
  className?: string;
}

const Menu = ({ entries, className }: MenuProps): React.ReactElement => {
  return (
    <nav className={clsx(styles.menuDesktop, className)}>
      <div className={styles.container}>
        {entries.map((entry, i: number) => (
          <MenuEntry entry={entry} key={i} />
        ))}
      </div>
    </nav>
  );
};

export default Menu;
