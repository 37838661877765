import React, { useContext, useEffect, useState } from 'react';
import { SingleEntry } from '../../graphql-fragments/fragments';

import { LocationContext } from '../../contexts/LocationContext';
import Logo from '../../images/logo.svg';
import whiteLogo from '../../images/logoWhite.svg';
import Layout from './Layout';

const CTA_QUERY_PARAM = 'cta';

type SanityLayoutProps = {
  children: React.ReactNode;
  menuEntries?: Array<SingleEntry>;
  initialVideoUrl?: string;
  withUserSession?: boolean;
  headerCtaButton?: {
    text: string;
    anchorLink: string;
  };
  floatingButton?: {
    text: string;
    anchorLink: string;
    isVisible?: boolean;
  };
};

const SanityLayout = ({
  menuEntries,
  children,
  headerCtaButton,
  floatingButton,
  withUserSession,
  initialVideoUrl,
}: SanityLayoutProps): React.ReactElement => {
  const [showHeaderCtaButton, setShowHeaderCtaButton] = useState<boolean>(true);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const ctaParamValue = urlSearchParams.get(CTA_QUERY_PARAM);

    if (ctaParamValue === 'none') {
      setShowHeaderCtaButton(false);
    }
  }, []);

  const location = useContext(LocationContext);

  return (
    <Layout
      initialVideoUrl={initialVideoUrl}
      pageTheme={'light'}
      floatingButton={floatingButton}
      headerConfig={{
        sticky: true,
        logoImageUrl: Logo,
        entries: menuEntries,
        withUserSession: withUserSession,
        ctaButton: headerCtaButton,
      }}
      footerConfig={{
        whiteLogoImageUrl: whiteLogo,
      }}
    >
      {children}
    </Layout>
  );
};

export default SanityLayout;
