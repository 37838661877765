import { clsx, slugify } from '@digital-spiders/misc-utils';
import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import serializers from '../../../serializers';
import { RawPortableText } from '../../../types/types';
import { getPortableTextAsString } from '../../../utils/sanity';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import Video from '../Video';
import * as styles from './TextAndMediaModule.module.scss';

export interface TextAndMediaModuleProps {
  title: string;
  mediaType: 'image' | 'video';
  image?: SanityImageType;
  video?: string;
  mediaSubtitle?: string;
  _rawText: RawPortableText;
  moduleAligment: 'textLeftImageRight' | 'imageLeftTextRight';
  id?: string;
  className?: string;
}

function TextAndMediaModule(props: TextAndMediaModuleProps): React.ReactElement {
  const { title, _rawText, mediaType, image, video, mediaSubtitle, moduleAligment, className, id } =
    props;

  return (
    <ModuleLayout
      id={id || slugify(title)}
      className={clsx(
        styles.content,
        styles.withColoredBackground,
        styles.noInternalTopPadding,
        className,
      )}
      contentClassName={styles.contentContainer}
    >
      <div
        className={clsx(
          styles.container,
          moduleAligment === 'textLeftImageRight' && styles.textLeftMediaRight,
        )}
      >
        {mediaType === 'video' && video && (
          <div className={clsx(styles.mediaContainer, mediaType === 'video' && styles.video)}>
            <Video url={video} />
          </div>
        )}

        {mediaType === 'image' && image && (
          <div className={clsx(styles.mediaContainer, image && styles.image)}>
            <Image image={image} />
          </div>
        )}

        <div className={clsx(styles.textBlockContainer)}>
          <div className={styles.textContentContainer}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>{title}</h2>
            </div>
            <BlockContent
              className={clsx(
                styles.portableContent,
                getPortableTextAsString(_rawText).length > 300 && styles.smallerFont,
              )}
              blocks={_rawText}
              renderContainerOnSingleChild
              serializers={serializers}
            />
            {mediaSubtitle && (
              <span className={clsx(styles.imageSubtitle, styles.mobile)}>{mediaSubtitle}</span>
            )}
          </div>

          {mediaSubtitle && (
            <span className={clsx(styles.imageSubtitle, styles.desktop)}>{mediaSubtitle}</span>
          )}
        </div>
      </div>
    </ModuleLayout>
  );
}

export default TextAndMediaModule;
