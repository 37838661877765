import { capitalize, sum } from '@digital-spiders/nodash';
import React, { useState } from 'react';
import { SingleEntry } from '../../graphql-fragments/fragments';

import { clsx, urlJoin } from '@digital-spiders/misc-utils';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../auth/AuthContext';
import { signOut } from '../../auth/api';
import { AUTHOR_NAME } from '../../constants';
import Button from './Button';
import DropDownMenu from './DropDownMenu';
import * as styles from './Header.module.scss';
import Menu from './Menu';
import MenuButton from './MenuButton';
import SmartLink from './SmartLink';

export type HeaderProps = {
  sticky?: boolean;
  logoImageUrl: string;
  entries?: Array<SingleEntry>;
  hideMenuEntries?: boolean;
  withUserSession?: boolean;
  ctaButton?: {
    text: string;
    anchorLink: string;
  };
};

const Header = ({
  sticky,
  logoImageUrl,
  entries,
  hideMenuEntries,
  withUserSession,
  ctaButton,
}: HeaderProps): React.ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // NOTE: this could probably be replaced by calculating the menu size
  // automatically with javascript.
  // TODO Check these sizes on each project
  const MENU_GAP_SIZE = 24; // spacing-medium
  const MENU_ENTRY_PADDING_SIZE = 8; // spacing-x-small
  const MENU_CHAR_SIZE = 10;
  // Small menus are shown from tablet vertical upwards
  const MEDIUM_MENU_MIN_SIZE = 500; // Fits only from table horizontal upwards
  const LARGE_MENU_MIN_SIZE = 700; // Fits only from laptop upwards
  const HUGE_MENU_MIN_SIZE = 800; // Doesn't fit anywhere

  // Calculates the menu size so we can inject corresponding classes
  // to hide the menu when it doesn't fit anymore
  const menuApproximateSize = entries
    ? sum(entries.map(({ title }) => title.length * MENU_CHAR_SIZE + MENU_ENTRY_PADDING_SIZE * 2)) +
      (entries.length - 1) * MENU_GAP_SIZE
    : 0;
  const menuSizeCategory =
    menuApproximateSize >= HUGE_MENU_MIN_SIZE
      ? 'huge'
      : menuApproximateSize >= LARGE_MENU_MIN_SIZE
      ? 'large'
      : menuApproximateSize >= MEDIUM_MENU_MIN_SIZE
      ? 'medium'
      : 'small';

  const { openAuthModal, userSession } = useAuth();

  return (
    <>
      {sticky && (
        <Helmet>
          <html className={styles.html}></html>
        </Helmet>
      )}
      <header
        className={clsx(
          styles.header,
          styles['headerWithMenu' + capitalize(menuSizeCategory)],
          sticky && styles.stickyHeader,
        )}
      >
        <div className={clsx(styles.headerContainer, hideMenuEntries && styles.noNavHeader)}>
          <div className={styles.logoWrapper}>
            <img className={styles.logoContainer} src={logoImageUrl} alt="Hook Point logo" />
            <div className={styles.visuallyHidden}>{AUTHOR_NAME}</div>
          </div>

          {!hideMenuEntries && entries && (
            <>
              <Menu entries={entries} className={clsx(styles.desktopMenu)}></Menu>

              <MenuButton
                className={styles.menuButton}
                isMenuOpen={isMenuOpen}
                onTouch={() => setIsMenuOpen(!isMenuOpen)}
              ></MenuButton>
            </>
          )}
          {withUserSession && (
            <div className={styles.buttonsContainer}>
              {userSession ? (
                <button onClick={() => signOut()} className={styles.signInButton}>
                  Log out
                </button>
              ) : (
                <>
                  <button
                    onClick={() => openAuthModal({ type: 'signIn' })}
                    className={styles.signInButton}
                  >
                    Sign in
                  </button>
                  <Button
                    onClick={() => openAuthModal({ type: 'signUp' })}
                    className={styles.signUpButton}
                  >
                    Sign up
                  </Button>
                </>
              )}
            </div>
          )}
          {ctaButton && (
            <div className={styles.buttonsContainer}>
              <SmartLink to={urlJoin('#', ctaButton.anchorLink)} className={styles.ctaButton}>
                <Button>{ctaButton.text}</Button>
              </SmartLink>
            </div>
          )}

          {!hideMenuEntries && entries && (
            <div className={styles.dropDownMenuWrapperWindow}>
              <div
                className={clsx(
                  styles.dropDownMenuWrapper,
                  isMenuOpen ? styles.menuOpen : styles.menuClosed,
                )}
              >
                <DropDownMenu
                  entries={entries}
                  onEntryClick={() => setIsMenuOpen(false)}
                  onSignInButtonClick={() => openAuthModal({ type: 'signIn' })}
                  onSignUpButtonClick={() => openAuthModal({ type: 'signUp' })}
                  onSignOutButtonClick={() => signOut()}
                ></DropDownMenu>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
