import React, { useState } from 'react';
import { BsExclamationSquare } from 'react-icons/bs';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

type InputFieldPropsBase = {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  value: string | null;
  onBlur: () => void;
  error: boolean;
  helperText: string;
  containerClass?: string;
  containerErrorClass?: string;
  helperTextClass?: string;
  helperTextErrorClass?: string;
  isPassword?: boolean;
} & (
  | {
      onChange: (value: string) => void;
      dontUnpackValue?: false;
    }
  | {
      onChange: (value: React.ChangeEvent) => void;
      dontUnpackValue: true;
    }
);

type InputFieldPropsInput = InputFieldPropsBase & {
  type: string;
  textarea?: never;
};

type InputFieldPropsTextArea = InputFieldPropsBase & {
  type?: never;
  textarea: true;
};

function InputField(props: InputFieldPropsInput): React.ReactElement;
function InputField(props: InputFieldPropsTextArea): React.ReactElement;

function InputField({
  id,
  name,
  type,
  placeholder,
  className,
  disabled,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  textarea,
  containerClass,
  containerErrorClass,
  helperTextClass,
  helperTextErrorClass,
  dontUnpackValue,
  isPassword,
  ...restProps
}: InputFieldPropsInput | InputFieldPropsTextArea): React.ReactElement {
  const [passwordVisibilityState, setPasswordVisibilityState] = useState<'hidden' | 'visible'>(
    'hidden',
  );

  return (
    <div
      className={containerClass + (error && containerErrorClass ? ' ' + containerErrorClass : '')}
    >
      {textarea ? (
        <textarea
          id={id}
          className={className}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value || ''}
          // @ts-ignore
          onChange={e => onChange(dontUnpackValue ? e : e.target.value)}
          onBlur={onBlur}
          {...restProps}
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type={isPassword && passwordVisibilityState === 'hidden' ? 'password' : 'text' || type}
            id={id}
            className={className}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            value={value || ''}
            onChange={e =>
              // @ts-ignore
              onChange(dontUnpackValue ? e : e.target.value)
            }
            onBlur={onBlur}
            {...restProps}
          />
          {isPassword &&
            ((passwordVisibilityState === 'visible' && (
              <FaEyeSlash
                style={{
                  width: 18,
                  color: 'var(--color-dark-blue)',
                  cursor: 'pointer',
                  flexShrink: 0,
                  marginLeft: 4,
                }}
                onClick={() => setPasswordVisibilityState('hidden')}
              />
            )) ||
              (passwordVisibilityState === 'hidden' && (
                <FaEye
                  style={{
                    width: 18,
                    color: 'var(--color-dark-blue)',
                    cursor: 'pointer',
                    flexShrink: 0,
                    marginLeft: 4,
                  }}
                  onClick={() => setPasswordVisibilityState('visible')}
                />
              )))}
        </div>
      )}
      <span
        className={
          helperTextClass + (error && helperTextErrorClass ? ' ' + helperTextErrorClass : '')
        }
        style={{ visibility: !helperText ? 'hidden' : undefined }}
      >
        <BsExclamationSquare />
        {helperText || 'x'}
      </span>
    </div>
  );
}

export default InputField;
