import React from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import { VersatileLink } from '../../types/types';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import Button, { ButtonProps } from './Button';
import * as styles from './ButtonLink.module.scss';
import SmartLink from './SmartLink';

export interface ButtonLinkProps extends ButtonProps {
  to: VersatileLink;
  linkClassName?: string;
  modalFormButtonClassName?: string;
}

const ButtonLink = ({ to, linkClassName, ...restProps }: ButtonLinkProps): React.ReactElement => {
  return (
    <SmartLink
      className={clsx(styles.link, linkClassName)}
      to={getUrlFromVersatileLink(to)}
      tabIndex={-1}
    >
      <Button {...restProps}></Button>
    </SmartLink>
  );
};

export default ButtonLink;
