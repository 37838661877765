import React, { useContext, useEffect, useState } from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import { AUTHOR_NAME, COPYRIGHT_DEFAULT_YEAR } from '../../constants';
import { LocationContext } from '../../contexts/LocationContext';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import * as styles from './Footer.module.scss';

export type LinkColumn = {
  title: string;
  entries: Array<ButtonLinkType>;
};

type SocialNetworkIconName = 'facebook' | 'linkedin' | 'whatsapp' | 'instagram';
export type SocialNetworksBlock = {
  title?: string;
  socialNetworks?: Array<{
    iconName: SocialNetworkIconName;
    url?: string;
  }>;
};

export type NewsletterBlock = {
  title: string;
};

export interface FooterProps {
  whiteLogoImageUrl: string;
}

const Footer = ({ whiteLogoImageUrl }: FooterProps): React.ReactElement => {
  const location = useContext(LocationContext)!;
  const [currentYear, setCurrentYear] = useState<number>(COPYRIGHT_DEFAULT_YEAR);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  function renderLogoBox() {
    return (
      <div className={styles.logoContainer}>
        <img className={styles.companyLogo} src={whiteLogoImageUrl} alt="Hook Point logo" />
      </div>
    );
  }

  function renderCopyright() {
    return (
      <div className={styles.copyright}>
        <div className={styles.copyrightDivider}></div> © {currentYear} {AUTHOR_NAME}
      </div>
    );
  }

  return (
    <footer className={clsx(styles.footer)}>
      <div className={styles.footerContainer}>
        <div className={styles.companyWrapper}>{renderLogoBox()}</div>
        {renderCopyright()}
      </div>
    </footer>
  );
};

export default Footer;
