import { clsx } from '@digital-spiders/misc-utils';
import React, { useEffect, useRef } from 'react';

import { useUpdateUrlFromFilters } from '@digital-spiders/react-hooks';
import { usePagination } from '../../utils/hooks';
import Card from './Card';
import * as styles from './ItemListing.module.scss';
import { Resource } from './modules/ResourcesModule';

const ITEMS_PER_PAGE = 8;

export interface ItemListingProps {
  items: Array<Resource>;
  onItemClick: (resource: Resource) => void;
  withPagination?: boolean;
  itemsPerPage?: number;
  className?: string;
}

const ItemListing = ({
  items,
  withPagination,
  itemsPerPage = ITEMS_PER_PAGE,
  className,
  onItemClick,
}: ItemListingProps): React.ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null); // Create a ref in your component

  const {
    currentPage,
    currentPageItems,
    loading,
    getPageUrlPart,
    renderPagination,
    resetPagination,
  } = usePagination(items, itemsPerPage, containerRef);

  useEffect(() => {
    resetPagination();
  }, [items.map(item => item._id).join(',')]);

  // Update url on filters state change
  useUpdateUrlFromFilters(() => [getPageUrlPart()], [currentPage]);

  return (
    <>
      <div
        className={clsx(className, styles.container, loading && styles.loading)}
        ref={containerRef}
      >
        {currentPageItems.map((item, i) => (
          <React.Fragment key={i}>
            <Card
              className={styles.card}
              title={item.title}
              image={item.image}
              category={item.resourceType}
              onCardClick={() => onItemClick(item)}
            />
          </React.Fragment>
        ))}
      </div>
      {withPagination && renderPagination()}
    </>
  );
};

export default ItemListing;
