// extracted by mini-css-extract-plugin
export var container = "TextAndMediaModule-module--container--d3897";
export var content = "TextAndMediaModule-module--content--3846d";
export var desktop = "TextAndMediaModule-module--desktop--f5202";
export var image = "TextAndMediaModule-module--image--563a6";
export var imageSubtitle = "TextAndMediaModule-module--imageSubtitle--56363";
export var imageWithTransparentBackground = "TextAndMediaModule-module--imageWithTransparentBackground--6bc2d";
export var media = "TextAndMediaModule-module--media--4d4f9";
export var mediaContainer = "TextAndMediaModule-module--mediaContainer--a5026";
export var mobile = "TextAndMediaModule-module--mobile--2f878";
export var noInternalTopPadding = "TextAndMediaModule-module--noInternalTopPadding--06764";
export var portableContent = "TextAndMediaModule-module--portableContent--edb1c";
export var smallerFont = "TextAndMediaModule-module--smallerFont--1f841";
export var textBlockContainer = "TextAndMediaModule-module--textBlockContainer--cbad9";
export var textContentContainer = "TextAndMediaModule-module--textContentContainer--61b72";
export var textLeftMediaRight = "TextAndMediaModule-module--textLeftMediaRight--e084b";
export var title = "TextAndMediaModule-module--title--e39fe";
export var video = "TextAndMediaModule-module--video--f6123";
export var withColoredBackground = "TextAndMediaModule-module--withColoredBackground--b0ed7";