import { clsx, slugify } from '@digital-spiders/misc-utils';
import { easeOut, motion, useScroll, useTransform } from 'framer-motion';
import React, { useRef } from 'react';
import { replaceNewLinesWithBr } from '../../../utils/utils';
import ButtonLink from '../ButtonLink';
import { getModuleId } from '../ModuleLayout';
import * as styles from './CTAModule.module.scss';

export interface CTAModuleProps {
  title: string;
  text: string;
  buttonText: string;
  buttonUrl: string;
  className?: string;
  id?: string;
}

function CTAModule(props: CTAModuleProps): React.ReactElement {
  const sectionRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start 125%', 'start 0%'],
  });

  const backgroundTranslateYMV = useTransform(scrollYProgress, [0, 1], ['20vh', '0vh'], {
    ease: easeOut,
  });
  const contentTranslateYMV = useTransform(scrollYProgress, [0, 1], ['-15vh', '0vh'], {
    ease: easeOut,
  });

  const { title, text, buttonText, buttonUrl, className, id } = props;

  return (
    <div
      id={id || slugify(title)}
      ref={sectionRef}
      className={clsx(styles.container, className)}
      style={{
        background: 'var(--color-white)',
      }}
    >
      <div id={getModuleId(id, title)} className={styles.scrollAnchor}></div>
      <motion.div className={styles.background} style={{ y: backgroundTranslateYMV }}></motion.div>
      <motion.div className={styles.content} style={{ y: contentTranslateYMV }}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
        <ButtonLink
          to={{ url: buttonUrl }}
          className={styles.button}
          backgroundColor="white"
          largeButton
        >
          {buttonText}
        </ButtonLink>
      </motion.div>
    </div>
  );
}

export default CTAModule;
