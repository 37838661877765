import React, { useState } from 'react';

import { clsx, slugify } from '@digital-spiders/misc-utils';
import { useAuth } from '../../auth/AuthContext';
import texture from '../../images/heroTexture.svg';
import { getPortableText } from '../../utils/sanity';
import * as styles from './AuthModal.module.scss';
import Button from './Button';
import Form, { FormFieldWithId } from './Form';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import TextLink from './TextLink';

export interface AuthModalProps {
  title: string;
  text?: string;
  className?: string;
}

const AuthModal = ({ title, text, className }: AuthModalProps): React.ReactElement => {
  const { authModalState, openAuthModal, closeAuthModal } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formFieldsWithIds =
    (authModalState?.type === 'signIn' &&
      ([
        {
          title: 'Email',
          fieldType: 'textSingleLine',
          isFieldRequired: true,
          withEmailValidation: true,
          crmFieldId: 'email',
          id: 'email',
        },
        {
          title: 'Password',
          fieldType: 'textSingleLine',
          isFieldRequired: true,
          crmFieldId: 'password',
          id: 'password',
          isPassword: true,
        },
      ] as Array<FormFieldWithId>)) ||
    (authModalState?.type === 'signUp' &&
      ([
        {
          title: 'Name',
          fieldType: 'textSingleLine',
          isFieldRequired: true,
          crmFieldId: 'givenName',
          id: 'name',
        },
        {
          title: 'Phone number',
          fieldType: 'textSingleLine',
          isFieldRequired: true,
          crmFieldId: 'phoneNumber',
          id: 'phoneNumber',
        },
        {
          title: 'Email',
          fieldType: 'textSingleLine',
          isFieldRequired: true,
          withEmailValidation: true,
          crmFieldId: 'email',
          id: 'email',
        },
        {
          title: 'Password',
          fieldType: 'textSingleLine',
          isFieldRequired: true,
          crmFieldId: 'password',
          id: 'password',
          isPassword: true,
        },
        {
          crmFieldId: 'acceptTheTerms',
          id: slugify('I agree to the terms and conditions'),
          fieldType: 'singleCheckbox',
          isFieldRequired: true,
          textNode: (
            <p>
              I agree to the{' '}
              <TextLink
                styleOnly
                onClick={e => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              >
                terms and conditions
              </TextLink>{' '}
            </p>
          ),
        },
        {
          crmFieldId: 'joinTheHookPointNewsletter',
          id: slugify('Join The Going Viral Newsletter!'),
          fieldType: 'singleCheckbox',
          isFieldRequired: false,
          _rawText: getPortableText(['Join The Going Viral Newsletter!']),
        },
      ] as Array<FormFieldWithId>)) ||
    (authModalState?.type === 'recoverPassword' &&
      ([
        {
          title: 'Email',
          fieldType: 'textSingleLine',
          isFieldRequired: true,
          withEmailValidation: true,
          crmFieldId: 'email',
          id: 'email',
        },
      ] as Array<FormFieldWithId>)) ||
    [];

  return (
    <div className={clsx(className, styles.container)}>
      <img src={texture} alt="" className={styles.texture} />

      <div className={styles.formContainer}>
        <Form
          key={authModalState?.type}
          text={
            <div className={styles.textContainer}>
              <h2 className={styles.title}>{title}</h2>
              {text && <p className={styles.text}>{text}</p>}
              {authModalState?.type !== 'recoverPassword' &&
                (authModalState?.type === 'signIn' ? (
                  <span className={styles.helper}>
                    Don't have an account?{' '}
                    <TextLink
                      styleOnly
                      onClick={() => openAuthModal({ type: 'signUp' })}
                      role="button"
                    >
                      Sign up
                    </TextLink>
                  </span>
                ) : (
                  <span className={styles.helper}>
                    Already have an account?{' '}
                    <TextLink
                      styleOnly
                      onClick={() => openAuthModal({ type: 'signIn' })}
                      role="button"
                    >
                      Sign in
                    </TextLink>
                  </span>
                ))}
            </div>
          }
          formType={
            (authModalState?.type === 'signIn' && 'signIn') ||
            (authModalState?.type === 'recoverPassword' && 'recoverPassword') ||
            'signUp'
          }
          submitButtonText={
            (authModalState?.type === 'signIn' && 'Sign in') ||
            (authModalState?.type === 'recoverPassword' && 'Send') ||
            'Sign up'
          }
          fields={formFieldsWithIds}
          afterFieldsLink={
            (authModalState?.type === 'signIn' && {
              text: 'I forgot my password',
              onClick: () => openAuthModal({ type: 'recoverPassword' }),
            }) ||
            (authModalState?.type === 'recoverPassword' && {
              text: 'Back to login',
              onClick: () => openAuthModal({ type: 'signIn' }),
            }) ||
            undefined
          }
          onSubmit={() => {
            if (authModalState?.type !== 'recoverPassword') {
              closeAuthModal();
              if (authModalState?.onSignInAction) {
                authModalState.onSignInAction();
              }
            }
          }}
          afterSubmitChildren={
            authModalState?.type === 'recoverPassword' ? (
              <div className={styles.textContainer}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.text}>
                  If your account is associated with this email address you will receive an email to
                  reset your password. If you don’t, please try another email.
                </p>
                <Button
                  outlined
                  onClick={() => openAuthModal({ type: 'signIn' })}
                  className={styles.button}
                >
                  Back to login
                </Button>
              </div>
            ) : (
              <></>
            )
          }
        />
        <TermsAndConditionsModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </div>
    </div>
  );
};

export default AuthModal;
