import { slugify } from '@digital-spiders/misc-utils';
import React, { useState } from 'react';
import { useAuth } from '../../../auth/AuthContext';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { BREAKPOINTS } from '../../../styles/breakpoints';
import SectionFilter from '../../SectionFilter';
import EmbedModal from '../EmbedModal';
import Image from '../Image';
import ItemListing from '../ItemListing';
import ModalOverlay from '../ModalOverlay';
import ModuleLayout from '../ModuleLayout';
import SearchBox from '../SearchBox';
import * as styles from './ResourcesModule.module.scss';

export type Resource = {
  _id: string;
  book: {
    _id: string;
  };
  title: string;
  section?: {
    _id: string;
    title: string;
    slug: {
      current: string;
    };
  };
  spreadsheetRowNumber: number;
} & (
  | {
      resourceType: 'image';
      image: SanityImageType;
      videoUrl?: never;
      spreadsheetUrl?: never;
    }
  | {
      resourceType: 'video';
      image: SanityImageType;
      videoUrl: string;
      spreadsheetUrl?: never;
    }
  | {
      resourceType: 'spreadsheet';
      image: SanityImageType;
      videoUrl?: never;
      spreadsheetUrl: string;
    }
  | {
      resourceType: 'link';
      image: SanityImageType;
      videoUrl?: never;
      spreadsheetUrl: string;
    }
);

export type ResourcesModuleProps = {
  title: string;
  text: string;
  resources: Array<Resource>;
  resourcesFilteredBySection: Array<Resource>;
  sections: Array<{
    _id: string;
    title: string;
    slug: {
      current: string;
    };
  }>;
  activeSectionId: string | null;
  setActiveSectionId: (sectionId: string | null) => void;
  itemsPerPage?: number;
  id?: string;
  className?: string;
};

function ResourcesModule(props: ResourcesModuleProps): React.ReactElement {
  const {
    title,
    text,
    resources,
    resourcesFilteredBySection,
    sections,
    activeSectionId,
    setActiveSectionId,
    className,
    itemsPerPage,
    id,
  } = props;

  const { openAuthModal } = useAuth();
  const { userSession } = useAuth();
  const [openedResource, setOpenedResource] = useState<Resource | null>(null);

  function onResourcePicked(resource: Resource) {
    if (userSession) {
      if (resource.resourceType === 'spreadsheet' || resource.resourceType === 'link') {
        window.open(resource.spreadsheetUrl, '_blank');
      } else {
        setOpenedResource(resource);
      }
    } else {
      openAuthModal({
        type: 'signUp',
        onSignInAction: () => {
          if (resource.resourceType === 'spreadsheet' || resource.resourceType === 'link') {
            window.open(resource.spreadsheetUrl, '_blank');
          } else {
            setOpenedResource(resource);
          }
        },
      });
    }
  }

  return (
    <ModuleLayout
      id={id || slugify(title)}
      className={className}
      title={title}
      text={text}
      childrenClassName={styles.content}
    >
      {resources.length > 0 && (
        <div className={styles.contentContainer}>
          <div className={styles.filtersContainer}>
            <div className={styles.searchContainer}>
              <span className={styles.filterTitle}>Search by title</span>
              <SearchBox
                withTypoCorrection
                entriesGroups={[
                  {
                    entries: resources.map(resource => ({
                      name: resource.title,
                      value: resource,
                    })),
                  },
                ]}
                noResultsMessage="There are no results for this search"
                placeholder="Resource title"
                onResultChosen={onResourcePicked}
              />
            </div>
            {sections.length > 0 && (
              <div className={styles.filterContainer}>
                <span className={styles.filterTitle}>Filter by section</span>
                <SectionFilter
                  activeSectionId={activeSectionId}
                  sections={sections}
                  setActiveSectionId={setActiveSectionId}
                />
              </div>
            )}
          </div>
          <ItemListing
            items={activeSectionId ? resourcesFilteredBySection : resources}
            withPagination
            itemsPerPage={itemsPerPage}
            onItemClick={onResourcePicked}
          />
        </div>
      )}
      <ModalOverlay
        open={!!openedResource}
        onClose={() => {
          setOpenedResource(null);
        }}
      >
        {openedResource && (
          <div className={styles.modalContainer}>
            {openedResource.section && (
              <span className={styles.sectionTitle}>{openedResource.section.title}</span>
            )}
            <h2 className={styles.modalTitle}>{openedResource.title}</h2>
            {openedResource.resourceType == 'video' && <EmbedModal url={openedResource.videoUrl} />}
            {openedResource.resourceType == 'image' && (
              <Image
                image={openedResource.image}
                dimensions={[
                  [500, 500],
                  [BREAKPOINTS.smallLaptop, 900, 700],
                ]}
              />
            )}
          </div>
        )}
      </ModalOverlay>
    </ModuleLayout>
  );
}

export default ResourcesModule;
