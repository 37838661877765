import { Link } from 'gatsby';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { checkIsInternalUrl, getInternalUrlPath } from '../../utils/utils';

export interface SmartLinkProps extends React.HTMLAttributes<HTMLElement> {
  to: string;
  children: React.ReactNode;
  className?: string;
  openInNewTab?: boolean;
}

export default React.forwardRef<any, SmartLinkProps>(function SmartLink(
  { openInNewTab, className, children, to, ...restProps },
  ref,
): React.ReactElement {
  if (!openInNewTab && checkIsInternalUrl(to)) {
    if (to.lastIndexOf('#') !== -1) {
      const anchorPart = to.substring(to.lastIndexOf('#'));

      return (
        <AnchorLink
          className={className}
          ref={ref}
          href={getInternalUrlPath(anchorPart)}
          {...restProps}
          offset="100"
        >
          {children}
        </AnchorLink>
      );
    } else {
      return (
        <Link className={className} ref={ref} to={getInternalUrlPath(to)} {...restProps}>
          {children}
        </Link>
      );
    }
  } else {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a className={className} ref={ref} href={to} target="_blank" rel="noopener" {...restProps}>
        {children}
      </a>
    );
  }
});
