import { urlJoin } from '@digital-spiders/misc-utils';
import { sort, uniq } from '@digital-spiders/nodash';
import { getSourceUrl } from '@digital-spiders/tracking-data';
import * as Sentry from '@sentry/react';
import { SITE_BASE_URL } from '../constants';
import supabaseClient from './supabaseClient';
export async function signUp({ email, password }: { email: string; password: string }) {
  const sourceUrl = getSourceUrl();
  return await supabaseClient.auth.signUp({
    email,
    password,
    options: {
      data: {
        signUpSourceUrl: sourceUrl,
        visitedUrls: [sourceUrl],
      },
    },
  });
}

export async function signIn({ email, password }: { email: string; password: string }) {
  const signInResponse = await supabaseClient.auth.signInWithPassword({
    email,
    password,
  });
  trackVisitedUrls().catch(error => {
    console.error(error);
    Sentry.captureException(error);
  });
  return signInResponse;
}

export async function signOut() {
  return await supabaseClient.auth.signOut();
}

export async function recoverPassword({ email, pathname }: { email: string; pathname: string }) {
  const cleanPathname = pathname.replaceAll(/(^\/+|\/+$)/g, '');
  return await supabaseClient.auth.resetPasswordForEmail(email, {
    redirectTo: urlJoin(SITE_BASE_URL, `/change-password?from=${cleanPathname}`),
  });
}

export async function changePassword({ password }: { password: string }) {
  return await supabaseClient.auth.updateUser({
    password,
  });
}

export async function trackVisitedUrls() {
  const {
    data: { session },
  } = await supabaseClient.auth.getSession();
  if (session) {
    const { visitedUrls = [] } = session.user.user_metadata;
    const sourceUrl = getSourceUrl();
    if (!visitedUrls.includes(sourceUrl)) {
      const newVisitedUrls = sort(uniq([...visitedUrls, sourceUrl]));
      return supabaseClient.auth.updateUser({
        data: {
          visitedUrls: newVisitedUrls,
        },
      });
    }
  }
  return null;
}
