/* eslint-disable @typescript-eslint/no-unsafe-return */
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { urlJoin } from '@digital-spiders/misc-utils';
import groq from 'groq';
import { ALL_LANGS, SITE_BASE_URL } from '../constants';
import { LocationContext } from '../contexts/LocationContext';
import { LocalizedSEOWebsite } from '../graphql-fragments/seo';
import { usePreviewData } from '../preview/previewUtils';
import { localize } from '../utils/sanity';
import SEO from './SEO';

interface LocalizedQueryData {
  site: {
    siteMetadata: {
      author: string;
    };
  };
  sanityHpWebsiteSettings: {
    seo: LocalizedSEOWebsite;
  };
}

interface PageSEOProps {
  defaultTitle?: string;
  defaultDescription?: string;
  defaultImageUrl?: string;
  pageSEO?: LocalizedSEOWebsite;
}

function PageSEO({
  defaultTitle,
  defaultDescription,
  defaultImageUrl,
  pageSEO,
}: PageSEOProps): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const location = useContext(LocationContext)!;
  const { i18n } = useTranslation();

  const websiteSeoStaticData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          author
        }
      }
      sanityHpWebsiteSettings {
        seo {
          ...SEOWebsite
        }
      }
    }
  `);

  const groqQuery = groq`{
    "sanityHpWebsiteSettings": *[_id == "hp-website-settings-singleton"][0] {
      seo {
        ...
      }
    }
  }`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const websiteSeoData = usePreviewData<any>(websiteSeoStaticData, {
    groqQuery,
    preprocessPreviewData: previewData => ({
      ...previewData,
      site: websiteSeoStaticData.site,
    }),
  });

  if (!websiteSeoData) {
    return <></>;
  }

  const langs = [i18n.language, ...ALL_LANGS.filter(lang => lang !== i18n.language)];
  const {
    site: {
      siteMetadata: { author },
    },
    sanityHpWebsiteSettings: { seo: websiteSEO },
  } = localize(websiteSeoData, langs) as LocalizedQueryData;
  const isHomepage = location.pathname === '/';
  const pageTitle = (isHomepage ? '' : pageSEO?.title) || defaultTitle;
  const pageDescription = pageSEO?.description || defaultDescription;
  const pageShareDescription = pageSEO?.shareDescription || pageDescription;
  const websiteTitle = (isHomepage && pageSEO?.title) || websiteSEO.title;
  const websiteShareTitle = websiteSEO.shareTitle || websiteTitle;
  const websiteShareDescription = websiteSEO.shareDescription || websiteSEO.description;

  return (
    <SEO
      title={pageTitle || websiteTitle}
      shareTitle={
        pageSEO?.shareTitle ||
        (pageTitle ? pageTitle + ' | ' + websiteShareTitle : websiteShareTitle)
      }
      description={pageDescription || websiteSEO.description}
      shareDescription={pageShareDescription || websiteShareDescription}
      url={urlJoin(SITE_BASE_URL, location.pathname)}
      canonicalUrl={urlJoin(SITE_BASE_URL, location.pathname)}
      imageUrl={pageSEO?.image?.asset?.url || defaultImageUrl || websiteSEO.image?.asset?.url}
      lang={i18n.language}
      author={author}
    />
  );
}

export default PageSEO;
