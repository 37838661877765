import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN_URL,
  environment: process.env.NODE_ENV,
  enabled: !!process.env.GATSBY_SENTRY_DSN_URL && process.env.NODE_ENV === 'production',
  beforeSend(event, hint) {
    // This is where we can filter events

    const error = hint?.originalException;
    // Filter out hydration errors, since I failed to fix them
    if (
      error &&
      typeof error === 'object' &&
      error.message &&
      (error.message.match(/Minified React error #423/i) ||
        error.message.match(/Minified React error #418/i) ||
        // Weird wistia error, happens in windows only
        error.message.match(
          /Non-Error promise rejection captured with value: Object Not Found Matching Id:/i,
        ))
    ) {
      return null;
    }
    return event;
  },
});
