import { useEffect, useState } from 'react';
import { VIDEO_TYPES_MAP } from '../constants';

export function getVideoTypeByUrl(url: string): keyof typeof VIDEO_TYPES_MAP {
  const type = Object.values(VIDEO_TYPES_MAP).find(({ formatRegex }) =>
    url.trim().match(formatRegex),
  )?.type;
  if (!type) {
    throw new Error(
      `Got video url "${url}" that didn't match any known type: ${Object.keys(VIDEO_TYPES_MAP).join(
        ', ',
      )}`,
    );
  }
  return type;
}

export function useWindowSize(): { width?: number; height?: number } {
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState({});

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
