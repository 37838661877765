import { Session } from '@supabase/supabase-js';
import React, { createContext, useContext, useEffect, useState } from 'react';
import supabaseClient from './supabaseClient';

export interface AuthValue {
  userSession: Session | null;

  isAuthModalOpen: boolean;
  authModalState: {
    type: 'signIn' | 'signUp' | 'recoverPassword';
    onSignInAction?: () => void;
  } | null;
  openAuthModal: (modalState: {
    type: 'signIn' | 'signUp' | 'recoverPassword';
    onSignInAction?: () => void;
  }) => void;
  closeAuthModal: () => void;
}

// We assume this AuthContext will never be used unless inside
// the AuthContext.Provider, and so the default is never used.
export const AuthContext = createContext<AuthValue>({} as AuthValue);

export const useAuth = () => useContext(AuthContext);

interface StateProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: StateProviderProps) {
  const [userSession, setUserSession] = useState<Session | null>(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
  const [authModalState, _setAuthModalState] = useState<{
    type: 'signIn' | 'signUp' | 'recoverPassword';
    onSignInAction?: () => void;
  } | null>(null);

  useEffect(() => {
    supabaseClient.auth.getSession().then(({ data: { session } }) => {
      setUserSession(session);
    });

    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange((_event, session) => {
      setUserSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  function openAuthModal(
    newAuthModalState: {
      type: 'signIn' | 'signUp' | 'recoverPassword';
      onSignInAction?: () => void;
    } | null,
  ) {
    if (newAuthModalState === null) {
      _setAuthModalState(newAuthModalState);
    } else {
      _setAuthModalState({
        ...authModalState,
        ...newAuthModalState,
      });
    }
    setIsAuthModalOpen(true);
  }

  function closeAuthModal() {
    setIsAuthModalOpen(false);
    if (authModalState) {
      // Wait a bit before resetting the modal state
      // to give it time to close before it's content changes
      setTimeout(() => {
        _setAuthModalState(null);
      }, 300);
    }
  }

  const auth: AuthValue = {
    userSession,
    isAuthModalOpen,
    authModalState,
    openAuthModal,
    closeAuthModal,
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
