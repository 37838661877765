import React, { useId } from 'react';
import { SanityImage } from 'sanity-image';

import { clsx, hash_cyrb53 } from '@digital-spiders/misc-utils';
import { SanityImageProps } from 'sanity-image/dist/types';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import { useSanityConfigData } from '../../utils/sanity';
import * as styles from './Image.module.scss';

export type BasicImage = {
  url: string;
  alt?: string;
};

export type ImageType = SanityImageType | BasicImage;

interface BaseImageProps
  extends Omit<SanityImageProps, 'id'>,
    Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'width' | 'height'> {
  className?: string;
}

type ImageProps = BaseImageProps &
  (
    | ({
        image: SanityImageType;
        cover?: boolean;
        eagerLoading?: boolean;
      } & (
        | {
            dimensions: [
              [number | undefined, number | undefined],
              ...Array<
                | [number, number | undefined, number | undefined]
                | [number, SanityImageType, number | undefined, number | undefined]
              >,
            ];
            width?: never;
            height?: never;
          }
        | {
            dimensions?: never;
            width?: number;
            height?: number;
          }
      ))
    | {
        image: BasicImage;
        cover?: never;
        eagerLoading?: never;
        dimensions?: never;
      }
    | {
        image: ImageType;
        cover?: never;
        eagerLoading?: never;
        dimensions?: never;
      }
  );

function Image({
  image,
  cover,
  eagerLoading,
  className,
  dimensions,
  ...restProps
}: ImageProps): React.ReactElement {
  const sanityConfigData = useSanityConfigData();

  function isSanityImage(image: BasicImage | SanityImageType): image is SanityImageType {
    return 'asset' in image && !!image.asset._id;
  }

  if (isSanityImage(image)) {
    let cleanDimensions: Array<[number, SanityImageType, number | undefined, number | undefined]>;
    if (!dimensions) {
      // If we don't have dimensions, convert width and height
      // to dimensions (if existing) and clear them from restProps
      cleanDimensions = [[0, image, restProps.width, restProps.height]];
      restProps.width = undefined;
      restProps.height = undefined;
    } else {
      // If we have dimensions, clean them into an easier to use format where
      // every entry has the minWidth (the first element gets 0) and image in index 1
      cleanDimensions = [[0, image, dimensions[0][0], dimensions[0][1]]];
      let currentImage = image;
      for (const dimension of dimensions.slice(1)) {
        if (dimension.length === 4) {
          currentImage = dimension[1];
          cleanDimensions.push([dimension[0] || 0, currentImage, dimension[2], dimension[3]]);
        } else {
          cleanDimensions.push([dimension[0] || 0, currentImage, dimension[1], dimension[2]]);
        }
      }
    }

    // Add the 'c' to ensure it starts with a letter
    const imageClassBase = 'c' + hash_cyrb53(useId(), { format: 'hex' }).slice(0, 4);

    return (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: cleanDimensions
              .map(
                (dimension, i) =>
                  `@media (min-width: ${dimension[0]}px) {${cleanDimensions
                    .map(
                      (_, j) => `.${imageClassBase}-${j} {display: ${i == j ? 'block' : 'none'}}`,
                    )
                    .join(' ')}}`,
              )
              .join('\n'),
            //
          }}
        ></style>
        {cleanDimensions.map((dimension, i) => {
          const [, dimensionImage, width, height] = dimension;
          return (
            <SanityImage
              key={i}
              className={clsx(styles.image, className, imageClassBase + '-' + i)}
              {...sanityConfigData}
              id={dimensionImage.asset._id}
              hotspot={dimensionImage.hotspot}
              crop={dimensionImage.crop}
              preview={dimensionImage.asset.metadata?.lqip}
              alt={dimensionImage.asset.altText || ''}
              loading={eagerLoading ? 'eager' : 'lazy'}
              mode={cover ? 'cover' : 'contain'}
              width={width}
              height={height}
              {...restProps}
            />
          );
        })}
      </>
    );
  }

  return <img src={image.url} alt={image.alt || ''} className={className}></img>;
}

export default Image;
