import BlockContent from '@sanity/block-content-to-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Select, { StylesConfig } from 'react-select';

import { clsx } from '@digital-spiders/misc-utils';
import { removeItem } from '@digital-spiders/nodash';
import { addContactToKeap } from '@hook-point/api-client';
import { BsExclamationSquare } from 'react-icons/bs';
import { changePassword, recoverPassword, signIn, signUp } from '../../auth/api';
import { FUNCTIONS_BASE_URL, ROOT_DOMAIN } from '../../constants';
import { LocationContext } from '../../contexts/LocationContext';
import serializers from '../../serializers';
import { RawPortableText } from '../../types/types';
import { FormError, GenericField, useForm, useFormField } from '../../utils/forms';
import * as styles from './Form.module.scss';
import InputField from './InputField';
import TextLink from './TextLink';

export type FormField = {
  crmFieldId?: string;
  isFieldRequired?: boolean;
} & (
  | {
      fieldType: 'textSingleLine';
      title: string;
      isGroupCategory?: never;
      withEmailValidation?: boolean;
      displayType?: never;
      isPassword?: boolean;
    }
  | {
      fieldType: 'textMultiline';
      title: string;
      isGroupCategory?: never;
      withEmailValidation?: never;
      displayType?: never;
      isPassword?: never;
    }
  | {
      fieldType: 'select';
      title: string;
      isGroupCategory: boolean;
      withEmailValidation?: never;
      selectOptions: Array<{
        title: string;
        crmValueStored: string;
      }>;
      displayType: 'dropdown' | 'multiCheckbox';
      isPassword?: never;
    }
  | {
      fieldType: 'singleCheckbox';
      title?: never;
      isGroupCategory?: never;
      withEmailValidation?: never;
      displayType?: never;
      isPassword?: never;
      _rawText: RawPortableText;
      textNode?: React.ReactNode;
    }
);

export type FormFieldWithId = {
  id: string;
} & FormField;

type Option = {
  value: string;
  label: string;
};

const customStyles: StylesConfig<Option> = {
  option: (defaultStyles, props) => ({
    ...defaultStyles,
    backgroundColor:
      (props.isSelected && 'var(--color-red)') ||
      (props.isFocused && 'var(--color-white)') ||
      'var(--color-white)',
    cursor: props.isSelected ? 'default' : 'pointer',
    color:
      (props.isSelected && 'var(--color-white)') ||
      (props.isFocused && 'var(--color-red)') ||
      'var(--color-dark-blue)',
    padding: 'var(--spacing-xx-small)',
    margin: 0,
    fontFamily: 'var(--body-font-family)',
  }),
  indicatorSeparator: (defaultStyles, props) => ({
    ...defaultStyles,
    display: 'none',
  }),
  indicatorsContainer: (defaultStyles, props) => ({
    ...defaultStyles,
    marginRight: 'var(--spacing-small)',
  }),
  singleValue: (defaultStyles, props) => ({
    ...defaultStyles,
    fontFamily: 'var(--body-font-family)',
    fontSize: 'var(--fs-t-sm)',
    color: 'var(--color-title-body-100)',
  }),
  control: (defaultStyles, props) => ({
    ...defaultStyles,
    marginTop: 'calc(var(--spacing-x-small) / 2)',
    backgroundColor: 'white',
    height: 40,
    boxShadow: '0 0 0 0 transparent',
    borderRadius: 'calc(var(--radius-small) / 2)',
    border: props.isFocused
      ? '1px solid var(--color-dark-blue)'
      : '1px solid var(--color-medium-grey)',
    '&:hover': {
      borderColor: 'none',
    },
    opacity: props.isDisabled ? '0.8' : '1',
  }),
  menu: (defaultStyles, props) => ({
    ...defaultStyles,
    borderRadius: 'calc(var(--radius-small) / 2)',
    overflow: 'hidden',
    fontSize: 'var(--fs-t-sm)',
  }),
  menuList: (defaultStyles, props) => ({
    ...defaultStyles,
    padding: 0,
    margin: 0,
  }),
};

type FormProps = {
  formType: 'signIn' | 'signUp' | 'recoverPassword' | 'changePassword';
  text?: React.ReactElement;
  fields: Array<FormFieldWithId>;
  submitButtonText?: string;
  afterSubmitChildren?: React.ReactNode;
  afterFieldsLink?: {
    text: string;
    onClick: () => void;
  };
  className?: string;
  withColumnLayout?: boolean;
  onSubmit?: () => void;
};

const Form = ({
  formType,
  text,
  fields,
  afterFieldsLink,
  submitButtonText,
  afterSubmitChildren,
  className,
  withColumnLayout,
  onSubmit,
}: FormProps): React.ReactElement => {
  const location = useContext(LocationContext)!;

  const fieldsByName = Object.fromEntries(
    fields.map(field => [
      field.id,
      field.fieldType === 'singleCheckbox'
        ? useFormField<boolean>(false, [
            ...(field.isFieldRequired
              ? ([value => (!value ? 'Please check this field' : null)] as const)
              : []),
          ])
        : useFormField<string>('', [
            ...(field.isFieldRequired ? (['required'] as const) : []),
            ...(field.withEmailValidation && field.fieldType === 'textSingleLine'
              ? (['email'] as const)
              : []),
          ]),
    ]),
  );

  const data: { [key: string]: string } = Object.fromEntries(
    fields
      .filter(field => field.crmFieldId)
      .map(({ id, crmFieldId }) => [crmFieldId, fieldsByName[id].value]),
  );

  const { getFieldProps, renderSubmitButton, renderFormMessage, submitState, onFieldUnfocus } =
    useForm({
      fieldsByName,
      onSubmit: internalOnSubmit,
      translateFunction: key => {
        return {
          'form.required_field_error': 'Please fill in the field above',
          'form.invalid_email_error': 'Invalid email (e.g. email@example.com)',
          'form.network_error': 'Network failed to send your request.',
          'form.unknown_error': 'An unexpected error occured. Please try again later.',
        }[key];
      },
    });

  async function internalOnSubmit() {
    const dataWithoutEmailAndPassword = Object.fromEntries(
      Object.entries(data).filter(entry => entry[0] !== 'email' && entry[0] !== 'password'),
    );

    let { email, password } = data;
    email = email?.trim();
    password = password?.trim();

    if (formType === 'signUp') {
      await addContactToKeap({
        rootDomain: ROOT_DOMAIN,
        email,
        acceptTheTerms: !!data.acceptTheTerms,
        joinTheHookPointNewsletter: !!data.joinTheHookPointNewsletter,
        data: dataWithoutEmailAndPassword,
        functionsBaseUrl: FUNCTIONS_BASE_URL,
      });
    }

    const authFunction = {
      signIn,
      signUp,
      recoverPassword,
      changePassword,
    }[formType];
    const { error } = await authFunction({
      email,
      password,
      pathname: location.pathname,
    });

    if (error) {
      const userErrorMessages = {
        signIn: ['Invalid login credentials'],
        signUp: [
          'User already registered',
          'Password should be at least 6 characters.',
          'Unable to validate email address: invalid format',
        ],
        recoverPassword: ['Unable to validate email address: invalid format'],
        changePassword: ['Password should be at least 6 characters.'],
      }[formType];

      if (userErrorMessages.includes(error.message)) {
        throw new FormError(error.message, error.message, true);
      } else {
        throw error;
      }
    }

    if (onSubmit) {
      onSubmit();
    }
    return true;
  }

  const [minFirstScreenContainerHeight, setMinFirstScreenContainerHeight] = useState<number | null>(
    null,
  );
  const firstScreenContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Warning: this code doesn't allow for the container to shrink, only grow
    function updateMinFirstScreenContainerHeight() {
      if (firstScreenContainerRef.current) {
        setMinFirstScreenContainerHeight(
          firstScreenContainerRef.current.getBoundingClientRect().height,
        );
      }
    }
    updateMinFirstScreenContainerHeight();
    window.addEventListener('resize', updateMinFirstScreenContainerHeight);
  }, []);

  return (
    <div
      className={clsx(
        className,
        styles.formContainer,
        (withColumnLayout || submitState === 'submitted') && styles.columnLayout,
      )}
      id={`${formType}-form`}
      data-theme={'light'}
    >
      {(((submitState === 'ready' || submitState === 'submitting') && afterSubmitChildren) ||
        !afterSubmitChildren) &&
        text}
      {(submitState === 'ready' || submitState === 'submitting') && (
        <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
          <div className={styles.formScreen} ref={firstScreenContainerRef}>
            <div className={styles.infoBlock}>
              {fields.map((formField, i) => {
                if (formField.fieldType === 'singleCheckbox') {
                  const field = fieldsByName[formField.id] as GenericField<boolean>;
                  return (
                    <div
                      className={clsx(styles.checkboxContainer, !!field.error && styles.error)}
                      key={i}
                    >
                      <div className={styles.checkboxOptionContainer}>
                        <label htmlFor={formField.id} className={styles.checkboxLabel}>
                          {formField.textNode ? (
                            <div className={styles.singleCheckboxText}>{formField.textNode}</div>
                          ) : (
                            <BlockContent
                              renderContainerOnSingleChild
                              blocks={formField._rawText}
                              serializers={serializers}
                              className={styles.singleCheckboxText}
                            />
                          )}
                          <input
                            className={clsx(styles.checkbox)}
                            type="checkbox"
                            name={formField.id}
                            id={formField.id}
                            checked={!!field.value}
                            onChange={event => {
                              field.setValue(event.target.checked);
                              field.setError('');
                            }}
                            onBlur={() => onFieldUnfocus(field)}
                          />
                          <span className={styles.checkmark} />
                        </label>
                      </div>
                      <div
                        className={!!field.error ? styles.helperText : ''}
                        style={{
                          display: !field.error ? 'none' : undefined,
                        }}
                      >
                        <BsExclamationSquare title={field.error} />
                      </div>
                    </div>
                  );
                }

                const field = fieldsByName[formField.id] as GenericField<string>;
                let inputElement: React.ReactElement;
                if (formField.fieldType === 'select') {
                  const selectFieldOptions = formField.selectOptions.map(selectOption => ({
                    value: selectOption.crmValueStored,
                    label: selectOption.title,
                  }));

                  if (formField.displayType === 'dropdown') {
                    inputElement = (
                      <div className={styles.selectContainer}>
                        <Select<Option>
                          inputId={formField.id + '-input'}
                          name={formField.id}
                          styles={customStyles}
                          options={selectFieldOptions}
                          placeholder=""
                          className={field.error ? styles.error : styles.select}
                          value={
                            selectFieldOptions.find(option => option.value === field.value) ||
                            undefined
                          }
                          onChange={currentOption => {
                            field.setValue(currentOption ? currentOption.value : null);
                            field.setError('');
                          }}
                          onBlur={() => onFieldUnfocus(field)}
                          blurInputOnSelect={false}
                        />
                        <div
                          className={!!field.error ? styles.helperText : ''}
                          style={{
                            visibility: !field.error ? 'hidden' : undefined,
                          }}
                        >
                          {field.error || 'x'}
                        </div>
                      </div>
                    );
                  } else {
                    inputElement = (
                      <div className={styles.checkboxContainer}>
                        {selectFieldOptions.map((selectOption, i) => (
                          <div className={styles.checkboxOptionContainer} key={i}>
                            <label htmlFor={selectOption.value} className={styles.checkboxLabel}>
                              <input
                                className={styles.checkbox}
                                key={i}
                                type="checkbox"
                                id={selectOption.value}
                                name={selectOption.value}
                                checked={JSON.parse(field.value || '[]').includes(
                                  selectOption.value,
                                )}
                                onChange={event => {
                                  const currentValueArray = JSON.parse(field.value || '[]');
                                  let newValueArray;
                                  if (
                                    event.target.checked &&
                                    !currentValueArray.includes(selectOption.value)
                                  ) {
                                    newValueArray = [...currentValueArray, selectOption.value];
                                  } else if (
                                    !event.target.checked &&
                                    currentValueArray.includes(selectOption.value)
                                  ) {
                                    newValueArray = removeItem(
                                      currentValueArray,
                                      selectOption.value,
                                    );
                                  }
                                  if (newValueArray) {
                                    field.setValue(
                                      newValueArray.length > 0 ? JSON.stringify(newValueArray) : '',
                                    );
                                    field.setError('');
                                  }
                                }}
                                onBlur={() => onFieldUnfocus(field)}
                              />
                              <span className={styles.checkmark} />
                              {selectOption.label}
                            </label>
                          </div>
                        ))}
                        <div
                          className={!!field.error ? styles.helperText : ''}
                          style={{
                            visibility: !field.error ? 'hidden' : undefined,
                          }}
                        >
                          {field.error || 'x'}
                        </div>
                      </div>
                    );
                  }
                } else if (formField.fieldType === 'textSingleLine') {
                  inputElement = (
                    <InputField
                      className={clsx(styles.input, field.error && styles.error)}
                      type={formField.withEmailValidation ? 'email' : 'text'}
                      id={formField.id + '-input'}
                      name={formField.id}
                      helperTextClass={clsx(
                        styles.helperText,
                        formField.isPassword && styles.isPassword,
                      )}
                      {...getFieldProps(field)}
                      isPassword={formField.isPassword}
                    />
                  );
                } else if (formField.fieldType === 'textMultiline') {
                  inputElement = (
                    <InputField
                      textarea
                      className={clsx(styles.input, styles.textInput, field.error && styles.error)}
                      id={formField.id + '-input'}
                      name={formField.id}
                      helperTextClass={styles.helperText}
                      {...getFieldProps(field)}
                    />
                  );
                } else {
                  //@ts-ignore
                  throw new Error(`Unknown fieldType "${formField.fieldType}"`);
                }

                return (
                  <div
                    key={formField.id}
                    className={clsx(styles.inputContainer, !!field.error ? styles.error : '')}
                  >
                    <label htmlFor={formField.id + '-input'} className={styles.label}>
                      {formField.title}
                      {formField.isFieldRequired ? '*' : ''}
                    </label>
                    {inputElement}
                  </div>
                );
              })}
              {afterFieldsLink && (
                <TextLink
                  styleOnly
                  onClick={() => afterFieldsLink.onClick()}
                  className={styles.afterFieldsLink}
                >
                  {afterFieldsLink.text}
                </TextLink>
              )}
              {renderSubmitButton({
                labels: {
                  ready: submitButtonText || 'Submit',
                  submitting: 'Loading...',
                  submitted: 'Submitted',
                },
                btnClasses: {
                  common: styles.submitButton,
                  ready: styles.formReady,
                  submitting: styles.formSubmitting,
                  submitted: styles.formSubmitted,
                },
              })}
              {renderFormMessage({
                styles: {
                  formMessage: styles.formMessage,
                  formMessageSuccess: styles.formMessageSuccess,
                  formMessageError: styles.formMessageError,
                },
              })}
            </div>
          </div>
        </form>
      )}
      {submitState === 'submitted' && afterSubmitChildren}
    </div>
  );
};

export default Form;
