import React, { useEffect, useState } from 'react';

import { clsx, urlJoin } from '@digital-spiders/misc-utils';
import * as Sentry from '@sentry/react';
import { trackVisitedUrls } from '../../auth/api';
import { useAuth } from '../../auth/AuthContext';
import { HIDE_NAV_QUERY_PARAM } from '../../constants';
import calendar from '../../images/calendar.svg';
import { useGlobalState } from '../../state/globalStateContext';
import { useActivateGoogleOptimize } from '../../utils/hooks';
import AuthModal from './AuthModal';
import ButtonLink from './ButtonLink';
import Footer, { FooterProps } from './Footer';
import Header, { HeaderProps } from './Header';
import * as styles from './Layout.module.scss';
import ModalOverlay from './ModalOverlay';
import Video from './Video';

interface LayoutProps {
  pageTheme: 'dark' | 'light';
  children: React.ReactNode;
  headerConfig: HeaderProps;
  footerConfig: FooterProps;
  initialVideoUrl?: string;
  floatingButton?: {
    isVisible?: boolean;
    text: string;
    anchorLink: string;
  };
}

const Layout = ({
  pageTheme,
  children,
  headerConfig,
  footerConfig,
  initialVideoUrl,
  floatingButton,
}: LayoutProps): React.ReactElement => {
  const isHidden = useActivateGoogleOptimize();

  const { isNavHidden, setIsNavHidden } = useGlobalState();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const navParamValue = urlSearchParams.get(HIDE_NAV_QUERY_PARAM);

    if (navParamValue !== null) {
      setIsNavHidden(true);
    }
  }, []);

  const { isAuthModalOpen, authModalState, openAuthModal, closeAuthModal } = useAuth();

  const [showInitialVideo, setShowInitialVideo] = useState(false);

  useEffect(() => {
    const videoShown = window.localStorage.getItem('initialVideoShown');
    if (!videoShown) {
      setShowInitialVideo(true);
      window.localStorage.setItem('initialVideoShown', 'true');
    }

    trackVisitedUrls().catch(error => {
      console.error(error);
      Sentry.captureException(error);
    });
  }, []);

  return (
    <div className={styles.container} style={isHidden ? { opacity: 0 } : undefined}>
      <Header {...headerConfig} hideMenuEntries={isNavHidden}></Header>
      <div
        role="main"
        className={styles.main}
        data-theme={(pageTheme === 'dark' && 'dark') || (pageTheme === 'light' && 'light') || ''}
      >
        {children}
      </div>
      <Footer {...footerConfig}></Footer>
      {initialVideoUrl && (
        <ModalOverlay open={showInitialVideo} onClose={() => setShowInitialVideo(false)}>
          <Video url={initialVideoUrl} />
        </ModalOverlay>
      )}
      <ModalOverlay open={!!isAuthModalOpen} onClose={() => closeAuthModal()}>
        <AuthModal
          title={
            (authModalState?.type === 'recoverPassword' && 'Reset password') ||
            (authModalState?.onSignInAction
              ? 'Interested in this content?'
              : authModalState?.type === 'signIn'
              ? "It's good to see you're back!"
              : 'Enhance Your Learning Journey - Sign Up Now!')
          }
          text={
            (authModalState?.type === 'recoverPassword' &&
              'Please enter your email address. We’ll email you a link to reset your password.') ||
            (authModalState?.onSignInAction || authModalState?.type === 'signUp'
              ? 'Fill out the form to have access to the online resources of the book.'
              : undefined)
          }
        />
      </ModalOverlay>
      {floatingButton && (
        <ButtonLink
          to={{ anchorLink: urlJoin('#', floatingButton.anchorLink) }}
          className={clsx(styles.floatingButton, floatingButton.isVisible && styles.visible)}
        >
          <img src={calendar} alt="" />
          {floatingButton.text}
        </ButtonLink>
      )}
    </div>
  );
};

export default Layout;
