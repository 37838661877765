// extracted by mini-css-extract-plugin
export var button = "SearchBox-module--button--ec07e";
export var checkmark = "SearchBox-module--checkmark--25c71";
export var container = "SearchBox-module--container--3379e";
export var groupTitle = "SearchBox-module--groupTitle--4bde4";
export var hiddenLabel = "SearchBox-module--hiddenLabel--c06f7";
export var labelContainer = "SearchBox-module--labelContainer--25088";
export var matchLocation = "SearchBox-module--matchLocation--9e6a3";
export var matchLocationSeparator = "SearchBox-module--matchLocationSeparator--f2cfa";
export var matchLocationType = "SearchBox-module--matchLocationType--03122";
export var matchLocationValue = "SearchBox-module--matchLocationValue--d1d21";
export var moreResultsEllipsis = "SearchBox-module--moreResultsEllipsis--b81ca";
export var noResultsMessage = "SearchBox-module--noResultsMessage--19f86";
export var radioButton = "SearchBox-module--radioButton--d6efe";
export var radioButtonContainer = "SearchBox-module--radioButtonContainer--26b4b";
export var radioButtonLabel = "SearchBox-module--radioButtonLabel--a3897";
export var result = "SearchBox-module--result--8c649";
export var resultName = "SearchBox-module--resultName--8f9ed";
export var resultsBox = "SearchBox-module--resultsBox--2305f";
export var resultsGroup = "SearchBox-module--resultsGroup--c2574";
export var resultsGroupTitled = "SearchBox-module--resultsGroupTitled--984bd";
export var searchBox = "SearchBox-module--searchBox--97a10";
export var searchDescription = "SearchBox-module--searchDescription--0575b";
export var searchInput = "SearchBox-module--searchInput--8614d";
export var selectedResult = "SearchBox-module--selectedResult--6179f";
export var similarResults = "SearchBox-module--similarResults--d96a6";
export var similarResultsContainer = "SearchBox-module--similarResultsContainer--3af10";
export var similarResultsTitle = "SearchBox-module--similarResultsTitle--aa3de";