import { clsx } from '@digital-spiders/misc-utils';
import React from 'react';
import { useAuth } from '../../auth/AuthContext';
import { SingleEntry } from '../../graphql-fragments/fragments';
import Button from './Button';
import * as styles from './DropDownMenu.module.scss';
import MenuEntry from './MenuEntry';
interface DropDownMenuProps {
  entries: Array<SingleEntry>;
  onEntryClick?: (entry: SingleEntry, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onSignInButtonClick?: React.MouseEventHandler<HTMLElement>;
  onSignUpButtonClick?: React.MouseEventHandler<HTMLElement>;
  onSignOutButtonClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
}

const DropDownMenu = ({
  entries,
  className,
  onEntryClick,
  onSignInButtonClick,
  onSignUpButtonClick,
  onSignOutButtonClick,
}: DropDownMenuProps): React.ReactElement => {
  const { openAuthModal, userSession } = useAuth();
  return (
    <div className={clsx(styles.container, className)}>
      {entries.map((entry, i) => (
        <React.Fragment key={i}>
          <MenuEntry
            entry={entry}
            key={i}
            className={styles.link}
            onClick={event => onEntryClick && onEntryClick(entry, event)}
          />
        </React.Fragment>
      ))}
      <div className={styles.divider}></div>

      <div className={styles.buttonsContainer}>
        {userSession ? (
          <button onClick={onSignOutButtonClick} className={styles.signInButton}>
            Log out
          </button>
        ) : (
          <>
            <button onClick={onSignInButtonClick} className={styles.signInButton}>
              Sign in
            </button>
            <Button onClick={onSignUpButtonClick} className={styles.signUpButton}>
              Sign up
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default DropDownMenu;
